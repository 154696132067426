<script>
import TextField from "@fields/TextField"

export default {
    name: "EmailField",
    extends: TextField,
    props: {
        type: {
            type: String,
            default: "email"
        }
    },
}
</script>
