import { reactive, ref, watch } from "@compose"
import { convertFields } from "./convertFields"
import {
    checkHoneypot,
    exposeFieldData,
    packFormData,
    packFieldData
} from "./manageFieldData"

export const formSchema = {
    name: {
        type: String,
        default: ""
    },
    fields: {
        type: Object,
        default: () =>({})
    },
    formMeta: {
        type: Object,
        default: () => ({})
    }
}


export default (formData, { emit }) => {
    const {
        name = "form",
        fields = {},
        formMeta = {}
    } = formData

    const appliedFields = ref([])
    let formMetaFields = reactive([])
    let formError = reactive({
        message: "",
        current: false,
        disableForm: false
    })
    const isValid = ref(false)
    const isSpam = ref(false)
    // const isProcessed = ref(false)

    watch(
        () => fields,
        (fields) => handleInitialization({ fields }),
        // { deep: true }
    )

    watch(
        () => formMeta.fields,
        (fields) => {
            formMetaFields = Object.entries(fields)
                .map(
                    ([ name, value ]) => ({ name, value })
                )
        },
        { deep: true }
    )

    function checkValidity ({ $el }) {
        return $el.checkValidity()
    }

    function handleInitialization ({ fields }) {
        appliedFields.value = convertFields({ fields, formName: name })

        let emittedFields = { fields: appliedFields }

        emit(
            "fields-initialized",
            emittedFields
        )

        return emittedFields
    }

    function handleFieldValueUpdates ({ name, value }) {
        setErrorState({ current: false })

        let field = appliedFields.value.find(
            (field) => field.name == name
        )

        field.value = value

        let emittedFields = { fields: appliedFields }

        emit(
            "fields-updated",
            emittedFields
        )
    }

    function handleSubmit ({ target }) {
        const fields = appliedFields.value
        const { formData } = packFormData({ fields, formMetaFields })
        const { fieldData } = packFieldData({ fields, formMetaFields })

        isValid.value = checkValidity({ $el: target })
        isSpam.value = checkHoneypot({ fields })

        let payload = {
            fields: exposeFieldData({ fields, formMetaFields }),
            fieldData,
            formData,
            isSpam,
            isValid,
            setErrorState
        }

        try {
            // if (!isValid) throw {
            //     message: FORM_ERRORS.INVALID_FORM,
            //     disableForm: false
            // }
            if (isSpam) {
                setErrorState({ current: false, disableForm: true })
            }

            emit(
                "submit",
                payload
            )

            return payload
        }
        catch ({ message, disableForm }) {
            return setErrorState({ message, disableForm })
        }

    }

    function setErrorState ({ current = !formError.current, disableForm = false, message = "" }) {
        formError = {
            current,
            disableForm,
            message,
        }
    }

    return {
        formError,
        appliedFields,

        handleFieldValueUpdates,
        handleSubmit,
        setErrorState,
        // listeners: {
        //     handleFieldValueUpdates,
        //     // handleSubmit,
        //     // setErrorState
        // }
    }
}
