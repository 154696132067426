<script>
import useAbstract from "@useAbstract"

import content from "@content/modules/HipcooksCookbook.yaml"

import ActionControl from "@controls/ActionControl"
import BackdropMedia from "@media/BackdropMedia"
import ContentStrata from "@strata/ContentStrata"


export default {
    name: "HipcooksCookbook",
    extends: useAbstract,
    components: {
        ActionControl,
        BackdropMedia,
        ContentStrata
    },
    setup () {
        return {
            content,
        }
    }
}
</script>


<template lang="pug">
content-strata.ContentStrata--cookbook(
    v-bind="content"
    :merge-vefa-style="$vefa"
)
    template(#append-ContentStrata)
        backdrop-media(
            v-bind="content"
        )

    template(#extend-ContentStrata-header)
        action-control(
            :route="content.link"
            :apply-vefa-style="appendLocalVefa($appStyles.controls.minor, $vefa.control)"
        )
            template(#ActionControl-label) {{ content.linkLabel }}
</template>


<vefa>
control:
    element:
        class: control:primary edge:xyEq
        o-base: iso:yStart150
        o-economy: type:subhed

hed:
    class: type:major--weight:700 color:copy
    o-condensed: type:hed
    o-economy: type:xhed
    o-enhanced: type:broadside

lede:
    class: type:minor--weight:400 color:copy
    o-condensed: type:base
    o-economy: type:major
    o-enhanced: type:subhed
</vefa>


<style lang="stylus">
.ContentStrata--cookbook
    --iso-xEq: minmax(ratio(2), 1fr)
    --iso-yEq: ratio(2)
    --columns: minmax(min-content, ratio(53))
    // 46.25vw = 555H/1200W
    --rows: s("calc(46.25vw - %s)", ratio(4)) auto

    .media--backdrop
        overflow: hidden

        img
            filter: brightness(1.25)
            transform: scale(1.20)

            +o-economy()
                filter: brightness(1)
                transform: scale(1)
</style>
