import { computed, reactive, ref } from "@compose"
import useVefa, { appendLocalVefa } from "@useVefa"


export default (props, context) => {
    const instance = ref(props.value)

    const isDirty = computed(
        () => instance.value !== props.initialValue
    )

    const localValue = computed({
        get: () => instance.value,
        set: (value) => {
            instance.value = value
            context.emit(
                "input",
                {
                    name: props.name,
                    isDirty: isDirty.value,
                    value
                }
            )
        }
    })

    const { $appStyles } = useVefa()

    const generatedProps = reactive({
        ...appendLocalVefa(
            $appStyles.field.base.control,
            // $appStyles.field.control
        ),
        id: props.id,
        name: props.name,
        placeholder: props.placeholder,
        required: props.required,
        type: props.type
    })

    return {
        localValue,
        generatedProps
    }
}
