<script>
import useAbstract from "@useAbstract"

export default {
    name: "FlashBlock",
    extends: useAbstract,
    props: {
        category: {
            type: String,
            default: "info"
        },
        message: {
            type: String,
            default: null
        },
        tag: {
            type: String,
            default: "li"
        }
    }
}
</script>

<template lang="pug">
component(
    class="motif:dark"
    o-base="flex:h--p:middle-s:middle pad:xEq200 pad:yEq100"
    v-if="message"
    :is="tag"
)
    p {{ message }}
</template>
