// parsers
//  --------------------
//  IMPORTS
//  DATA MODELS / STATE
//  PRIVATE FUNCTIONS
//  PUBLIC FUNCTIONS
//  EXTENDERS
//  COMPOSITIONS



//  IMPORTS
//  --------------------
import snakeCase from "lodash/snakeCase"
import camelCase from "lodash/camelCase"
import mapKeys from "lodash/mapKeys"

//  PRIVATE FUNCTIONS
//  --------------------
function isObject ( item ) {
    return item && typeof item === "object" && !Array.isArray( item )
}

//  recursive function that cases keys appropriately for the browser (JS)`
//  or for the back-end (server)
function batchConvertCasing ( payload, casingFn ) {
    // make into a Map
    const testPayloadLookup = {
        [Array.isArray( payload )] ( payload ) {
            return payload.map(
                ( item ) => batchConvertCasing( item, casingFn )
            )
        },

        [isObject( payload )] ( payload ) {
            return Object.fromEntries(
                Object.entries( payload ).map(
                    ([ key, value ]) => {
                        const newKey = casingFn( key )
                        const newPayload = batchConvertCasing( value, casingFn )

                        return [ newKey, newPayload ]
                    }
                )
            )
        },
        default ( payload ) {
            return payload
        }
    }

    return testPayloadLookup["true"]
        ? testPayloadLookup["true"]( payload )
        : testPayloadLookup["default"]( payload )
}



function reportParserError ( error ) {
    // if ( process.env.NODE_ENV === "production" ) {
    //     // eslint-disable-next-line
    //     SentryHandler?.report( error )
    // }

    console.error( error )

    return false
}



function testForExistence ({ id, required, onError }) {
    const element = document.getElementById( id )

    //  we don't have this in the template
    //  if required, lets throw an error
    //  else fail silently as we might just have it as an optional load
    if ( !element && required ) throw onError

    return element || false
}



//  PUBLIC FUNCTIONS
//  --------------------
export function batchCamelCasing ( payload ) {
    return ( typeof payload === "string" )
        ? camelCase( payload )
        : batchConvertCasing( payload, camelCase )
}

export function batchSnakeCasing ( payload ) {
    return ( typeof payload === "string" )
        ? snakeCase( payload )
        : batchConvertCasing( payload, snakeCase )
}


//  rename function name for public developer use case
export const mapServerKeysToUiKeys = batchCamelCasing
export const mapUiKeysToServerKeys = batchSnakeCasing

export function normalizeAppConstants ( payload ) {
    return Array.isArray( payload )
        ? payload.map(
            ( item ) => mapKeys(
                item,
                ( value, key ) => camelCase( key )
            )
        )
        : mapKeys(
            payload,
            ( value, key ) => camelCase( key )
        )
}



export function parseAppRoutes ({ id, required = false, replaceEmpty = false }) {
    //  used to parse app URL routes embedded in app rendered template

    try {
        const refined = parseTemplateData({ id, required })

        // @info    certain Django routes require a data id to be declared.
        // @        since our data is dynamic, we can override this
        // @        by supplying a '0' and replacing it with a named param in JS
        return Object.entries( refined ).reduce(
            ( routeObj, [ key, route ]) => {
                routeObj[key] = route.replace( "/0", replaceEmpty || "/:id" )

                return routeObj
            },
            {}
        )
    }
    catch ( error ) {
        return required ? false : {}
    }
}



export function parseHtml ( id ) {
    const htmlEl = document.getElementById( id )

    return htmlEl ? htmlEl.innerHTML : ""
}



// exported to retain naming schema for older code
export const parseObject = parseTemplateData



export function parseTemplateData ({ id, required = false }) {
    //  used to parse json data embedded within a server delivered template
    try {
        const element = testForExistence({
            id,
            onError: `[Hipcooks] Template data of #${ id } is not in template and is marked as required.`,
            required,
        })

        return element
            ? JSON.parse( element.innerHTML )
            : {}
    }
    catch ( error ) {
        reportParserError( error )
    }
}



export function parseTemplateElement ({ id, required = false }) {
    //  parses <template> element within an html document sent from server
    //  returns a string that can be used in v-html
    try {
        const templateBlock = testForExistence({
            id,
            onError: `[Hipcooks] Template element of #${ id } is not in template and is marked as required.`,
            required,
        })

        if ( !templateBlock ) return ""

        const childNodes = templateBlock.content.childNodes
        const nodeReducer = ( result, node ) => result + ( node.outerHTML || node.nodeValue )

        //  reduce the document fragment into a string
        const elementGroup = Array.prototype.reduce.call(
            childNodes,
            nodeReducer,
            ""
        )

        //  remove the empty string/text nodes
        return elementGroup.trim()
    }
    catch ( error ) {
        reportParserError( error )
    }
}



export function splitContentIntoParagraphs ( contentBlock ) {
    return contentBlock
        .split( "\n\n" )
        .map( ( item ) => `<p>${ item }</p>` )
        .join( "" )
}


export function stripHtml ( html ) {
    try {
        const doc = new DOMParser().parseFromString( html, "text/html" );

        return doc.body.textContent || "";
    }
    catch ( error ) {
        return ""
    }
}
