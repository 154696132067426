import CancelIcon from "@icons/CancelIcon.svg"
import CheckIcon from "@icons/CheckIcon.svg"
import EditIcon from "@icons/EditIcon.svg"
import EmptyCartIcon from "@icons/EmptyCartIcon.svg"
import ExpandIcon from "@icons/ExpandIcon.svg"
import ExtendedMenuIcon from "@icons/ExtendedMenu.svg"
import FullCartIcon from "@icons/FullCartIcon.svg"
import FacebookIcon from "@icons/FacebookIcon.svg"
import GoogleIcon from "@icons/GoogleIcon.svg"
import InstagramIcon from "@icons/InstagramIcon.svg"
import LocationIcon from "@icons/LocationIcon.svg"
import PinterestIcon from "@icons/PinterestIcon.svg"
// import RegisterIcon from "@icons/RegisterIcon.svg"
import TrashIcon from "@icons/TrashIcon.svg"
import UserIcon from "@icons/UserIcon.svg"
import YelpIcon from "@icons/YelpIcon.svg"

export default function (Vue) {
    Vue.component("CancelIcon", CancelIcon)
    Vue.component("CheckIcon", CheckIcon)
    Vue.component("EditIcon", EditIcon)
    Vue.component("EmptyCartIcon", EmptyCartIcon)
    Vue.component("ExpandIcon", ExpandIcon)
    Vue.component("ExtendedMenuIcon", ExtendedMenuIcon)
    Vue.component("FullCartIcon", FullCartIcon)
    Vue.component("FacebookIcon", FacebookIcon)
    Vue.component("GoogleIcon", GoogleIcon)
    Vue.component("InstagramIcon", InstagramIcon)
    Vue.component("LocationIcon", LocationIcon)
    Vue.component("PinterestIcon", PinterestIcon)
    // Vue.component("RegisterIcon", RegisterIcon)
    Vue.component("TrashIcon", TrashIcon)
    Vue.component("UserIcon", UserIcon)
    Vue.component("YelpIcon", YelpIcon)
}
