<script>
import useAbstract from "@useAbstract"

import { ref } from "@compose"
import { parseObject } from "@utils/parsers"

const cartMeta = parseObject({ id: "CART_META" })
const actionMeta = parseObject({ id: "NAV_ROUTES" })
const user = parseObject({ id: "CURRENT_USER" })

export default {
    extends: useAbstract,
    props: {
        showActionControls: {
            type: Boolean,
            default: false
        },
        showExtendedControl: {
            type: Boolean,
            default: false
        },
        showLocationControl: {
            type: Boolean,
            default: false
        },
        showLoginControl: {
            type: Boolean,
            default: false
        }
    },
    setup () {
        const redirectOnSignIn = `?next=${ window.location.href }`

        return {
            actionMeta: ref( actionMeta ),
            cartMeta: ref( cartMeta ),
            redirectOnSignIn,
            user: ref( user )
        }
    },
}
</script>

<template lang="pug">
nav(
    v-bind="$vefa.element"
)
    template(
        v-if="showActionControls"
    )
        template(
            v-if="showLoginControl"
        )
            template(
                v-if="user.isActive"
            )
                a(
                    :href="actionMeta.MyHipcooks"
                    v-bind="$vefa.link"
                )
                    user-icon(
                        v-bind="$vefa.icon"
                    )

                    span Hello, {{ user.firstName }}

                hr(
                    v-bind="$vefa.rule"
                )

                a(
                    :href="actionMeta.LogOut"
                    v-bind="$vefa.link"
                )
                    span Log Out

            template(
                v-else
            )
                a(
                    :href="`${ actionMeta.LogIn }${ redirectOnSignIn }`"
                    v-bind="$vefa.link"
                )
                    user-icon(
                        v-bind="$vefa.icon"
                    )

                    span Log In / Register

            hr(
                v-bind="$vefa.rule"
            )

        a(
            v-bind="$vefa.link"
            :href="cartMeta.ROUTE"
        )
            template(
                v-if="cartMeta.ITEM_COUNT"
            )
                full-cart-icon(
                    v-bind="$vefa.icon"
                )
                span ( {{ cartMeta.ITEM_COUNT }} )

            template(v-else)
                empty-cart-icon(
                    v-bind="$vefa.icon"
                )

        hr(
            v-bind="$vefa.rule"
        )

    button(
        type="button"
        v-bind="$vefa.link"
        v-if="showExtendedControl"
        @click="$emit('extended-nav-state-change', $event)"
    )
        extended-menu-icon(
            v-bind="$vefa.icon"
        )

        span Search

    button(
        type="button"
        v-bind="$vefa.link"
        v-if="showLocationControl && !showExtendedControl"
        @click="$emit('location-nav-state-change', $event)"
    )
        location-icon(
            v-bind="$vefa.icon"
        )

        span Locations

    button(
        type="button"
        v-bind="appendLocalVefa($vefa.link, $vefa.closeLink)"
        v-if="!(showExtendedControl || showLocationControl)"
        @click="$emit('extended-nav-state-change', { state: false })"
    )
        cancel-icon(
            v-bind="$vefa.icon"
        )

        span Close
</template>

<vefa>
element:
    o-base: module:static flex:h--p:end--s:middle pad:xEq50 pad:yEq25 iso:xEqAuto
    o-condensed: flex:hAuto--p:end--s:middle pad:xyEq0 iso:xEq0

link:
    class: type--def:aliased--h:underline--def:noWrap color:primary--h:shift fx:shift
    o-base: flex:hAuto--p:start--s:middle pad:xEq50 pad:yEq25 rhy:xStart25
    o-condensed: type:minor
    o-enhanced: type:base pad:xEq100 rhy:xStart50

closeLink:
    o-base: optics:a11y
    o-condensed: module:static flex:hAuto--p:start--s:middle optics:yes

icon:
    class: optics:alpha50
    o-base: module:static aspect:xyEq125--text

rule:
    class: edge:vr--set:secondary optics:alpha50
    o-base: module:static aspect:yEq100--text
    style:
        --edge-width: 1px
</vefa>
