import { render, staticRenderFns } from "./CorporatePrimaryActions.vue?vue&type=template&id=05fcf880&lang=pug&"
import script from "./CorporatePrimaryActions.vue?vue&type=script&lang=js&"
export * from "./CorporatePrimaryActions.vue?vue&type=script&lang=js&"
import style0 from "./CorporatePrimaryActions.vue?vue&type=style&index=0&lang=stylus&module=true&"
import style1 from "./CorporatePrimaryActions.vue?vue&type=style&index=1&lang=stylus&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CorporatePrimaryActions.vue?vue&type=custom&index=0&blockType=vefa"
if (typeof block0 === 'function') block0(component)

export default component.exports