<script>
import { ref } from "@compose"
import { parseObject } from "@utils/parsers"

import useAbstract from "@useAbstract"

export default {
    extends: useAbstract,
    setup () {
        return {
            socialRoutes: ref(parseObject({ id: "SOCIAL_LINKS" }))
        }
    }
}
</script>

<template lang="pug">
nav(
    v-bind="$vefa.element"
)
    template(
        v-for="route, service in socialRoutes"
    )
        a.social-link(
            v-bind="$vefa.link"
            :href="route"
            :key="service"
        )
            component(
                v-bind="$vefa.icon"
                :is="`${ service }-icon`"
            )

            span(
                v-bind="$vefa.label"
            ) {{ service }}
</template>

<vefa>
element:
    o-base: module:static flex:h--p:middle--s:middle grid:h--gap:xEq100
    o-condensed: grid--gap:xEq200
    o-enhanced: module--self:yEq


link:
    class: motif:primary type:xhed fx:shift--h:bright edge--shift:drop50--set:radiiCircle
    o-base: module:static pad:xyEq75
    style:
        --fx-bright: .2

icon:
    class: color:primary icon:onlyStroke
    o-base: aspect:xyEq100--rhy
    o-condensed: aspect:xyEq150--rhy
    o-extended: aspect:xyEq100--text

label:
    class: optics:a11y
</vefa>
