import locationsData from "@content/locations.yaml"

import { computed, reactive } from "@compose"

let propSchema = {
    currentId: null,
    currentRoute: null
}
export default ({ currentId = null, currentRoute = null } = propSchema) => {

    const locations = reactive(locationsData)

    function makeFullName (name) {
        return `Hipcooks ${ name }`
    }
    const currentLocation = computed(
        () => {
            let location = locations[currentId] || {}
            location = (currentRoute && locations.find( ({ slug }) => slug == currentRoute )) || location
            location.fullName = makeFullName(location.name)
            return location
        }
    )

    const sortedLocations = computed(
        () => Object.values(locations)
            .map(
                route => ({
                    ...route,
                    fullName: makeFullName(route.name),
                    route: route.slug,
                    ...route.contact
                })
            )
            .sort(getNavOrder)
    )

    return {
        currentLocation,
        locations,
        sortedLocations,
    }
}

function getNavInfo ({ item, navGroup }) {
    if (!item.navigation) return false

    return item.navigation
        .find( ({ group }) => group == navGroup )
}

function getNavOrder (sortA, sortB) {
    const navGroup = "locations"
    let sortNavA= getNavInfo({ item: sortA, navGroup })
    let SortNavB = getNavInfo({ item: sortB, navGroup })

    return sortNavA.order - SortNavB.order
}
