<script>
import { computed } from "@compose"
import useAbstract from "@useAbstract"

export default {
    extends: useAbstract,
    props: {
        hed: {
            type: String,
            default: null
        },
        lede: {
            type: String,
            default: null
        },
        tag: {
            type: String,
            default: "aside"
        }
    },
    setup (props) {
        let {
            hed,
            lede
        } = props

        const hasHeader = computed(
            () => {
                return !!(hed || lede)
            }
        )
        return {
            hasHeader
        }
    }
}
</script>


<template lang="pug">
component(
    v-bind="$vefa.element"
    :is="tag"
)
    slot(
        name="prepend-ContentBlock"
        v-bind="$appliedVefa"
    )

    header.block-header(
        v-bind="$vefa.header"
        v-if="hasHeader"
    )
        slot(
            name="ContentBlock-hed"
            v-bind="$appliedVefa"
        )
            h2.block-hed(
                v-bind="$vefa.hed"
            ) {{ hed }}

        slot(
            name="CotentBlock-lede"
            v-bind="$appliedVefa"
        )
            p.block-lede(
                v-bind="$vefa.lede"
                v-if="lede"
            ) {{ lede }}

        slot(
            name="extend-ContentBlock-header"
            v-bind="$appliedVefa"
        )

    .block-body(
        v-bind="$vefa.body"
    )
        slot(
            name="ContentBlock-body"
            v-bind="$appliedVefa"
        )

    slot(
        name="append-ContentBlock"
        v-bind="$appliedVefa"
    )


</template>


<vefa>
element:
    o-base: module:static flex:v grid:content optics:xyEq--hide

header:
    class: optics--hide:empty
    o-base: flex:v--p:start--s:start module:static--z:above
    # pad:yEnd100  why?

hed:
    class: type:hed--weight:700 color:primary

lede:
    class: type:base--weight:700 color:primary

body:
    o-base: module:static--z:above
</vefa>


<style lang="stylus">
.ContentBlock--
    +class-partial()
        grid-template-areas: unquote('
            ". . ."
            ". header ."
            ". body ."
            ". . ."
        ')

        .block-body
            grid-area: body

        .block-header
            grid-area: header
</style>
