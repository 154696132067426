<script>
import { ref, reactive } from "@compose"
import { serializeFormData } from "@forms/manageFieldData"

import useAbstract from "@useAbstract"
import useLocations from "@locations/useLocations"

import SignupContent from "@content/modules/NewsletterSignup.yaml"

import ActionControl from "@controls/ActionControl"
import BackdropMedia from "@media/BackdropMedia"
import ContentStrata from "@strata/ContentStrata"
import FormBlock from "@blocks/FormBlock"

import TextField from "@fields/TextField"
import EmailField from "@fields/EmailField"

const locations = {
    default: 1,
    EastLA: 1,
    WestLA: 1,
    OC: 3,
    Portland: 5,
    SanDiego: 9,
    Seattle: 11,
    WoodlandHills: 7
}

export default {
    name: "NewsletterSignup",
    extends: useAbstract,
    components: {
        ActionControl,
        BackdropMedia,
        ContentStrata,
        FormBlock
    },
    setup () {
        const instance = ref(SignupContent)


        const currentId = window.location.pathname.split("/")[1]

        const { currentLocation } = useLocations({ currentId })

        function syncLocation () {
            //  for now, we are mapping to existing forms on activeCampaign until we can see about importing/embedding
            const { location } = currentLocation.value
            return locations[location] || locations["default"]
        }

        const { postRoute } = instance.value

        //  firlds
        //  data model for form fields
        const fields = reactive({
            fullname: {
                component: TextField,
                placeholder: "First and Last Name",
                value: ""
            },
            email: {
                component: EmailField,
                placeholder: "Email",
                required: true,
                value: ""
            }
        })

        //  form
        //  the data object for the form to be created
        //  name: assign a form name,
        //  fields: assign the fields model
        //  honeypot: when form is on public side, we can add a honeypot w
        //  which will help weed out spammers
        //  metadata: data to be passed with form that is not editable in the form
        const form = reactive({
            name: "newsletter",
            formMeta: {
                fields: {}
            },
            fields
        })

        function handleSubmit ({ fieldData }) {
            return window.location.assign(
                `${ postRoute }${ syncLocation() }?${ serializeFormData({ fields: fieldData }) }&location=${currentLocation.value.location}`
            )
        }

        return {
            currentLocation,
            fields,
            form,
            instance,
            postRoute,

            handleSubmit,
            syncLocation
        }
    }
}
</script>

<template lang="pug">
content-strata.ContentStrata--NewsletterSignup(
    v-bind="instance"
    :merge-vefa-style="$vefa"
)
    template(#append-ContentStrata)
        backdrop-media(
            v-bind="instance"
        )

    template(#ContentStrata-body)
        form-block.FormBlock--NewsletterSignup(
            v-bind="form"
            :append-vefa-style="$vefa.form"
            @submit="handleSubmit"
        )
            template(#FormBlock-actions)
                action-control(
                    class="control:secondary"
                    type="submit"
                    :apply-vefa-style="appendLocalVefa($appStyles.controls.standard, $appStyles.controls.addFx)"
                    xdisabled="error.disableForm"
                )
                    template(#ActionControl-label) Sign up
</template>

<vefa>
element:
    o-base: module:static flex:v grid:content--gap:yEq100 optics:xyEq--hide

header:
    class: optics--hide:empty
    o-base: flex:v--p:start--s:start module:static--z:above
    # pad:yEnd100  why?

hed:
    class: type:hed--weight:700 color:white

lede:
    class: type:base--weight:700 color:white

body:
    o-base: module:static--z:above

form:
    element:
        o-base: grid:v--gap:xEq150--gap:yEq100

    fieldList:
        o-base: module:balance flex:v--p:start--s:full grid:v--gap:xEq300--gap:yEq100

    actionMenu:
        o-base: grid:h--gap:xEq100
        o-economy: grid:h--p:full--gap:xEq100

    error:
        element:
            class: edge:xyEq--set:warn
            o-base: module:static--span:content pad:xyEq50 rhy:yStart50

        body:
            class: type:minor--face:chrome--weight:300 color:warn

    field:
        element:
            o-base: module:static
</vefa>

<style lang="stylus">
.ContentStrata--NewsletterSignup
    --columns: minmax(min-content, ratio(48))
    --rows: auto auto
    --iso-xEq: minmax(ratio(2), 1fr)
    --iso-yEq: ratio(1)
    // --gap-yEq: ratio(1)

    .media--backdrop
        > img
            object-position: bottom left

            +o-economy()
                object-position: center

.FormBlock--NewsletterSignup
    --columns: minmax(var(--form-width, 1fr), 1fr) 1fr

    +o-economy()
        --form-width: ratio(34)

    .FormBlock-fieldlist
        --field-width: ratio(14)
        --columns: repeat(auto-fill, minmax(var(--field-width, 1fr), 1fr))

    .FormBlock-actions
        --control-width: 150px
        --columns: repeat(auto-fit, minmax(max-content, var(--control-width, 1fr)))

        +o-economy()
            --control-width: 1fr
</style>
