<script>
export default {
    name: "VuelessView"
}
</script>

<template lang="pug">
span(
    class="optics:no"
    v-if="false"
    v-once
)
</template>
