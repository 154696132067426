<script>
import { computed, ref } from "@compose"
import useAbstract from "@useAbstract"
import useLocations from "@locations/useLocations"

import ActionControl from "@controls/ActionControl"
import ContentCard from "@cards/ContentCard"

export default {
    name: "LocationChooser",
    extends: useAbstract,
    components: {
        ActionControl,
        ContentCard
    },
    setup () {
        const {
            sortedLocations
        } = useLocations()

        const defaultLocation = ref( sortedLocations.value[0].slug )

        const handleChange = ({ target: { value } }) => {
            defaultLocation.value = value
            handleLocationChange()
        }

        const handleLocationChange = () => {
            location.assign( defaultLocation.value )
        }

        const openLocations = computed(
            () => sortedLocations.value.filter(
                ({ active }) => !!active
            )
        )

        const comingSoonLocations = computed(
            () => sortedLocations.value.filter(
                ({ active }) => !active
            )
        )

        return {
            comingSoonLocations,
            defaultLocation,
            handleChange,
            handleLocationChange,
            openLocations,
            sortedLocations,
        }
    }
}
</script>

<template lang="pug">
content-card.ContentCard--locationChooser(
    hed="Pick a Hipcooks near you"
    tag="form"
    :append-vefa-style="$vefa"
    :remove-vefa-style="$vefa.remove"
)
    template(#ContentCard-body)
        label(
            v-bind="$vefa.label"
            for="locationControl"
        )
            location-icon.LocationChooser-control-icon(
                v-bind="$vefa.icons.preSelect"
            )

            select#locationControl.LocationChooser-control(
                v-bind="$vefa.routeList"
                @change="handleChange"
            )
                optgroup(
                    label="Open Locations"
                )
                    option(
                        v-for="route in openLocations"
                        :value="route.slug"
                    ) {{ route.name }}

                optgroup(
                    disabled
                    label="Coming Soon"
                )
                    option(
                        v-for="route in comingSoonLocations"
                        :value="route.slug"
                    ) {{ route.name }}

            expand-icon.LocationChooser-control-icon(
                v-bind="$vefa.icons.postSelect"
            )

    template(#ContentCard-footer)
        action-control(
            :apply-vefa-style="appendLocalVefa($appStyles.controls.standard, $vefa.control)"
            @click="handleLocationChange"
        )
            template(#ActionControl-label) Go!
</template>


<vefa>
element:
    class: motif:bright edge:xyEq--set:primary--set:drop200--set:radiiRounded
    o-enhanced: aspect:xEq80--view iso:xEqAuto
    o-extended: aspect:xEq70--view

content:
    o-base: module--gap:xyEq100
    o-condensed: flex:h--p:start--s:middle grid:content--gap:xyEq50
    o-economy: module--gap:xEq50
    o-enhanced: module--gap:xEq100

hed:
    class: type:subhed--face:hed--align:middle
    o-economy: pad:xEnd100 edge:xEnd--set:shade
    o-enhanced: type:hed
    o-extended: type:display

footer:
    o-condensed: pad:yStart0

routeList:
    class: type:major--weight:700
    o-base: flex:v--p:start--s:start pad:xEq200 pad:yEq50
    o-extended: module:static--self:yMiddle type:subhed--lead:base pad:xEq250 pad:yEq25

label:
    class: motif:bright color:primary edge:xyEq--set:tint
    o-base: flex:h--p:start--s:middle

control:
    element:
        class: control:secondary
        o-extended: type:subhed

icons:
    preSelect:
        o-base: aspect:xyEq100--text put:xStart--set:abs iso:xStart50
        o-extended: aspect:xyEq150--text

    postSelect:
        o-base: aspect:xyEq100--text put:xEnd--set:abs iso:xEnd50
        o-extended: aspect:xyEq150--text

remove:
    hed:
        class: type:major--weight:700--align:middle
</vefa>

<style lang="stylus">
.ContentCard--locationChooser
    .ContentCard-hed
        --edge-width: 4px

    .ContentCard-content
        +o-condensed()
            --columns: 0.5fr 1fr auto
            --rows: auto
            --iso-xyEq: ratio(0)
            grid-template-areas: unquote('
                    ". . . . ."
                    ". header body footer ."
                    ". . . . ."
                ')

        +o-economy()
            --iso-xyEq: ratio(0.25)
            --columns: max-content 1fr auto

        +o-enhanced()
            --iso-xyEq: ratio(1)
            // --col-template: max-content 1fr auto

.LocationChooser-control
    appearance: none
    background: transparent

.LocationChooser-control-icon
    pointer-events: none
</style>
