<script>
import { computed } from "@compose"
import useAbstract from "@useAbstract"
import useNavigation from "@navigation/useNavigation"

export default {
    name: "ContentCard",
    extends: useAbstract,
    props: {
        actionLabel: {
            type: String,
            default: null
        },
        hed: {
            type: String,
            default: null
        },
        lede: {
            type: [String, Array],
            default: null
        },
        media: {
            type: String,
            default: null
        },
        route: {
            type: String,
            default: null
        },
        slug: {
            type: String,
            default: null
        },
        tag: {
            type: String,
            default: "div"
        },
    },
    setup (props) {
        const { localizeRoute } = useNavigation()
        const localizedRoute = computed(
            () => props.route && localizeRoute(props)
        )

        const isLedeMultiElement = computed(
            () => Array.isArray(props.lede)
        )

        const applyRouteToElement = computed(
            () => props.tag == "a" && (props.slug || localizedRoute.value || props.route)
        )


        return {
            //  data props
            applyRouteToElement,
            isLedeMultiElement,
            localizedRoute,
        }
    }
}
</script>

<template lang="pug">
component(
    v-bind="$vefa.element"
    :href="applyRouteToElement"
    :is="tag"
)
    slot(name="prepend-ContentCard")

    .ContentCard-content(
        v-bind="$vefa.content"
    )
        header.ContentCard-header(
            v-bind="$vefa.header"
        )
            slot(name="ContentCard-hed")
                h3.ContentCard-hed(
                    v-bind="$vefa.hed"
                ) {{ hed }}

        .ContentCard-body(
            v-bind="$vefa.body"
        )
            slot(
                name="ContentCard-body"
            )
                template(v-if="isLedeMultiElement")
                    p(
                        v-for="copy in lede"
                        v-html="copy"
                    )
                template(v-else)
                    p(
                        v-html="lede"
                    )

        footer.ContentCard-footer(
            v-bind="$vefa.footer"
        )
            slot(
                name="ContentCard-footer"
                v-bind="{ ...$appliedVefa, localizedRoute }"
            )

    slot(name="append-ContentCard")
</template>

<vefa>
element:
    o-base: module:balance flex:h--p:full--s:full optics--hide:xyEq

content:
    o-base: flex:v grid:content--gap:xyEq50


header:
    o-base: flex:v--p:start--s:middle

hed:
    class: type:major--weight:700--align:middle color:copy
    # o-economy: type:subhed

body:
    class: type:minor--weight:400--align:middle color:copy
    o-base: module:balance

footer:
    o-base: flex:v--p:middle--s:grid pad:yStart50
    o-economy: flex:h--p:middle--s:middle pad:yStart0
</vefa>


<style lang="stylus">
.ContentCard-body
    grid-area: body

.ContentCard-content
    --rows: auto 1fr auto
    --iso-xyEq: 0
    grid-area: content
    grid-template-areas: unquote('
        ". . ."
        ". header ."
        ". body ."
        ". footer ."
        ". . ."
    ')

.ContentCard-footer
    grid-area: footer

.ContentCard-header
    grid-area: header
</style>
