export const convertFields = ({ formName, fields }) => {
    return Object.entries(fields)
        .reduce(
            convertFieldSchema(formName),
            []
        )
}

export const convertFieldSchema = (name) => {
    return (appliedFields, [fieldKey, field]) => {
        appliedFields.push({
            name: fieldKey,
            id: `${ name }_${ fieldKey }`,
            formName: name,
            ...field,
            initialValue: field.value,
            value: field.value,
        })

        return appliedFields
    }
}
