<script>
import useAbstract from "@useAbstract"
import useForms, { formSchema } from "@forms/useForms"

import FormErrorBlock from "@blocks/FormErrorBlock"
import FormFieldset from "@blocks/FormFieldset"

export default {
    name: "FormBlock",
    extends: useAbstract,
    props: {
        ...formSchema
    },
    components: {
        FormErrorBlock,
        FormFieldset
    },
    setup (props, context) {
        const {
            appliedFields,
            formError,
            setErrorState,
            handleFieldValueUpdates,
            handleSubmit
        } = useForms(props, context)


        return {
            appliedFields,
            formError,
            handleFieldValueUpdates,
            handleSubmit,
            setErrorState,
        }
    }
}
</script>

<template lang="pug">
form(
    v-bind="$vefa.element"
    @submit.prevent="handleSubmit"
)
    //- xsubmit.prevent="listeners.handleSubmit"

    slot(name="FormBlock-message")

    slot(name="FormBlock-error")
        form-error-block.FormBlock-error(
            v-bind="formError"
            :apply-vefa-style="$vefa.error"
        )

    slot(name="FormBlock-content")

    slot(name="FormBlock-fields")
        form-fieldset.FormBlock--body(
            :fields="appliedFields"
            :apply-vefa-style="$vefa"
            @input="handleFieldValueUpdates"
        )

    slot(name="replace-FormBlock-actions")
        footer.FormBlock-actions(
            v-bind="$vefa.actionMenu"
        )
            slot(name="FormBlock-actions")
</template>

<vefa>
element: {}

body: {}

actionMenu:
    o-base: flex:h--p:end--s:full
</vefa>
