<script>
import cards from "@content/cards.yaml"

import useAbstract from "@useAbstract"

import ContentStrata from "@strata/ContentStrata"
import InfoCard from "@cards/InfoCard"
import LocationChooser from "@apps/locations/LocationChooser"

export default {
    name: "CorporatePrimaryActions",
    extends: useAbstract,
    components: {
        ContentStrata,
        InfoCard,
        LocationChooser
    },
    setup () {
        return {
            cards
        }
    }
}
</script>

<template lang="pug">
content-strata.ContentStrata--primaryActions(
    :append-vefa-style="$vefa"
    tag="section"
)
    template(#ContentStrata-body)
        location-chooser

    template(#append-ContentStrata)
        .ContentStrata-extended--primaryActions(
            v-bind="$vefa.cardGroup"
        )
            info-card(
                tag="a"
                v-bind="cards.privateClassesCorporate"
                :class="$style.interactiveCard"
            )

            info-card(
                v-bind="cards.giftCertificatesCorporate"
            )

            info-card(
                tag="a"
                v-bind="cards.teamBuilding"
                :class="$style.interactiveCard"
            )
</template>

<vefa>
element:
    class: motif:tint
    o-base: flex:v--p:start--s:start grid:content

cardGroup:
    o-base: flex:v--p:full--s:full pad:xEq200 rhy:yStart100
    o-economy: flex:h grid:h--gap:xEq200 pad:xEq0 rhy:yStart0
</vefa>

<style lang="stylus" module>
.interactiveCard:hover
    > [class*="media--backdrop"] img
        transform: scale(1.05)
</style>

<style lang="stylus">
.ContentStrata--primaryActions
    --columns: minmax(min-content, ratio(60))
    --rows: auto ratio(2) auto
    --iso-xyEq: ratio(2)
    --iso-xEq: minmax(var(--iso-xyEq), 1fr)
    grid-template-areas: unquote('
        ". . ."
        ". body ."
        ". . ."
        "actions actions actions"
        ". . ."
    ')

.ContentStrata-extended--primaryActions
    grid-area: actions
    grid-template-areas: unquote('
        "a1 a3"
        ". ."
        "a2 a2"
    ')

    > :nth-child(1)
        grid-area: a1
    > :nth-child(2)
        grid-area: a2
    > :nth-child(3)
        grid-area: a3

    +o-condensed()
        > :nth-child(2)
            --iso-xEnd: ratio(.25)
            --iso-xStart: 1fr

    +o-economy()
        --columns: ratio(2) 3fr 3fr ratio(2)
        --rows: auto ratio(2) auto
        grid-template-areas: unquote('
            "a1 a1 a3 a3"
            ". . . ."
            ". a2 a2 ."
        ')

    +o-enhanced()
        --columns: 1fr 3fr 3fr 1fr

    +o-extended()
        --columns: 3fr 2fr 3fr
        --rows: auto
        grid-template-areas: unquote('
            "a1 a2 a3"
        ')

        > :nth-child(2)
            --rows: min-content
            --iso-xEnd: ratio(1)
            --iso-xStart: ratio(1)
            --iso-yStart: ratio(15)
            --iso-yEnd: ratio(2.5)
            align-self: center
            grid-template-areas: unquote('
                ". . ."
                ". content ."
                ". content ."
            ')
            padding: ratio(3.5) 0 ratio(2)

            .ContentCard-content
                padding-left: ratio(.5)
                padding-right: ratio(.5)

            .media--backdrop
                grid-row: 1 / span 2

                img
                    object-fit: contain

    +over(1600px)
        --pad-xEq: calc((100vw - 1200px) / 2)
</style>
