<script>
import { computed } from "@compose"
import useAbstract from "@useAbstract"
import useVefa from "@useVefa"

export default {
    name: "FormField",
    extends: useAbstract,
    props: {
        component: {
            type: Object,
            default: () => ({})
        },
        error: {
            type: Object,
            default: () => ({})
        },
        fieldMeta: {
            type: Object,
            default: () => ({
                element: {}
            })
        },
        formName: {
            type: String,
            default: null
        },
        helpText: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: ""
        },
        initialValue: {
            type: [String, Boolean],
            default: null
        },
        label: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: "text"
        },
        value: {
            type: [String, Boolean],
            default: null
        }
    },
    setup (props, context) {
        const { $appStyles } = useVefa()

        const generatedClasses = computed(
            () => {
                //  change to fieldMeta?.element?.class

                const {
                    element: {
                        class: fieldMetaClass = ""
                    }
                } = props.fieldMeta

                return [
                    `field--${ props.type }`,
                    `${ props.formName }-field--${ props.name }`,
                    fieldMetaClass
                ]
            }
        )

        const isRadioStyleGroup = [
            "checkbox",
            "radio"
        ].includes(props.type)

        const showLabelAsHeader = !isRadioStyleGroup // if radio style group, no header label
            && !!( !context.slots["replace-FormField-header"] && props.label ) // or if label and no slot

        return {
            appliedVefa: $appStyles.field.base,
            generatedClasses,
            showLabelAsHeader
        }
    }
}
</script>

<template lang="pug">
li(
    v-bind="appliedVefa.element"
    :class="generatedClasses"
)
    slot(name="replace-FormField-header")
        label.Formfield-label(
            v-bind="appliedVefa.label"
            v-if="showLabelAsHeader"
            :for="id"
        )
            span {{ label }}

            span.FormField-required(
                v-bind="appliedVefa.required"
                v-if="required"
            ) [required]

    slot(name="replace-FormField-error")
        aside.FormField-error(
            v-bind="appliedVefa.error.element"
            v-if="error && error.current"
        )
            header(
                v-bind="appliedVefa.error.header"
            )
                label(
                    v-bind="appliedVefa.error.hed"
                    :for="id"
                ).
                    {{ label }}

            p(
                v-bind="appliedVefa.error.body"
            )
                label(
                    :for="id"
                ).
                    {{ error.errorText || "There is an error with this field" }}

    slot(name="replace-FormField-body")
        .FormField-body(
            v-bind="appliedVefa.body"
        )
            slot(
                name="default"
                v-bind="{ appliedVefa }"
            )

    slot(name="replace-FormField-help")
        label.field-help(
            v-bind="appliedVefa.help"
            v-if="helpText"
            :for="id"
        ) {{ helpText }}


    slot(name="append-FormField")
</template>
