<script>
import useAbstract from "@useAbstract"
import FlashBlock from "@notifications/FlashBlock"

import { parseObject } from "@utils/parsers"
let FLASH_MESSAGES = parseObject({ id: "FLASH_MESSAGES" })

export default {
    name: "FlashList",
    extends: useAbstract,
    components: {
        FlashBlock
    },
    setup () {
        return {
            FLASH_MESSAGES
        }
    }
}
</script>

<template lang="pug">
ul.Flashes(
    o-base="flex:v pad:yEnd100 rhy:yStart"
    v-show="false"
)
    flash-block
</template>
