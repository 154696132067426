<script>
import useAbstract from "@useAbstract"

import SiteMasthead from "@runners/SiteMasthead"

export default {
    extends: useAbstract,
    components: {
        SiteMasthead
    }
}
</script>


<template lang="pug">
nav.SiteNavigation(
    v-bind="$vefa.element"
)
    site-masthead
        template(#SiteMasthead-CurrentLocationBadge-name)
            slot(name="CurrentLocationBadge-name")

        template(#append)
            slot(name="siteNavigationActions")

    slot(
        name="SiteNavigation-routes"
        v-bind="{ $appliedVefa: $vefa.routes }"
    )

    slot(name="append")
</template>


<style lang="stylus">
.SiteNavigation
    // use nav height variable for difference at smaller sizes, then override
    --nav-height: ratio(8.5)
    height: var(--nav-height)

    +o-enhanced()
        --nav-height: ratio(9)
        // hide overflow for offset of nav
        overflow: hidden

    +o-extended()
        --nav-height: ratio(10)

.SiteNavigation-nav
    +o-enhanced()
        transform: translateX(96px)

    +o-full()
        transform: translateX(0)
        --gap-xEq: ratio(4)
</style>


<vefa>
element:
    class: motif:primary
    o-economy: module:static--index:start--z:runners grid:v--s:start
    o-enhanced: pad:yEnd50

routes:
    element:
        class: SiteNavigation-nav
        o-economy: module:static--self:yEnd flex:h--p:end--s:middle grid:h--gap:xEq50 pad:xEnd100 pad:yEnd25
        o-enhanced: flex:h--p:start--s:middle grid:h--gap:xEq100
        o-extended: grid:h--gap:xEq150 pad:yStart50
        o-full: flex:h--p:middle--s:middle grid:h--gap:xEq150

    link:
        class: type:major--def:noWrap--def:aliased color:control--h:shift fx:shift
        o-base: pad:xEq25 pad:yEq10
        o-economy: type:minor
        o-enhanced: type:major pad:xyEq0
</vefa>
