<script>
import useAbstract from "@useAbstract"

import { corporate } from "@content/heroBlocks.yaml"

import HeroBlock from "@blocks/HeroBlock"


export default {
    name: "CorporateHeroBlock",
    extends: useAbstract,
    components: {
        HeroBlock,
    },
    setup () {
        return {
            heroContext: corporate
        }
    }
}
</script>

<template lang="pug">
hero-block.CorporateHeroBlock(
    v-bind="heroContext"
)
</template>
