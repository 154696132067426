<script>
import { computed } from "@compose"
import useAbstract from "@useAbstract"

import instance from "@content/modules/BlogPictureStream.yaml"

import ActionControl from "@controls/ActionControl"
import BackdropMedia from "@media/BackdropMedia"
import ContentStrata from "@strata/ContentStrata"
import { Carousel as VCarousel, Slide as VSlide } from "vue-carousel"

export default {
    name: "BlogPictureStream",
    extends: useAbstract,
    components: {
        ActionControl,
        BackdropMedia,
        ContentStrata,
        VCarousel,
        VSlide
    },
    setup () {
        const callToAction = computed(
            () => {
                let cta = instance.callToAction.split("//")

                return {
                    hed: cta[0].trim(),
                    subhed: cta[1].trim()
                }
            }
        )

        return {
            shouldAutoplay: true,
            callToAction,
            instance,
        }
    },
    computed: {
        actionStyles () {
            let styles = this.appendLocalVefa(this.$appStyles.controls.large, this.$appStyles.controls.addFx)
            return this.appendLocalVefa(styles, this.$vefa.control)
        }
    }
}
</script>

<template lang="pug">
content-strata.ContentStrata--carousel(
    :append-vefa-style="$vefa"
    :hed="instance.hed"
    :merge-vefa-style="$vefa.merge"
)
    template(
        #append-ContentStrata
    )
        .ContentStrata-media--loadin(
            v-bind="$vefa.carouselSlide"
        )
            img(
                v-bind="$vefa.carouselImage"
                :src="require(`@res/${ instance.images[0] }`)"
            )

        v-carousel.ContentStrata-media(
            loop
            :autoplay="shouldAutoplay"
            :per-page="1"
            :pagination-enabled="false"
            :autoplay-timeout="5000"
            :speed="0"
        )
            template(
                v-for="image in instance.images"
            )
                v-slide(
                    v-bind="$vefa.carouselSlide"
                )
                    img(
                        v-bind="$vefa.carouselImage"
                        :src="require(`@res/${ image }`)"
                    )

    template(#ContentStrata-body)
        action-control(
            :apply-vefa-style="actionStyles"
            :route="instance.link"
        )
            template(#ActionControl-content)
                h2(
                    v-bind="$vefa.control.hed"
                ) {{ callToAction.hed }}

                p(
                    v-bind="$vefa.control.subhed"
                ) {{ callToAction.subhed }}
</template>


<vefa>
header:
    o-base: flex:h--p:middle--s:middle

body:
    o-base: flex:v--p:middle--s:middle

carouselSlide:
    o-base: flex:v grid:v

carouselImage:
    class: module:fill

control:
    element:
        class: control:secondary edge:xyEq--drop:set200
        o-base: flex:vAuto--p:middle--s:middle
        o-condensed: rhy:yStart50
        o-extended: pad:xEq300 pad:yEq200 rhy:yStart100

    hed:
        class: type:major--weight:700
        o-condensed: type:subhed
        o-enhanced: type:hed
        o-extended: type:xhed

    subhed:
        class: type:chrome--weight:400
        o-condensed: type:base
        o-enhanced: type:major
        o-extended: type:subhed


merge:
    hed:
        class: type:subhed--face:hed--case:upper color:copy
        o-condensed: type:hed
        o-enhanced: type:display
        o-extended: type:broadside
</vefa>


<style lang="stylus">
.ContentStrata--carousel
    --rows: 58.125vw //s("calc(46.25vw - %s)", ratio(4))
    --iso-xyEq: ratio(3)
    grid-template-areas: unquote('
        ". header ."
        ". body ."
        ". . ."
    ')

    +o-economy()
        --iso-yStart: ratio(4)
        --rows: 555px - ratio(4)

    +o-extended()
        --iso-yStart: ratio(5)
        --rows: 800px - ratio(5)

.ContentStrata-media
    +class-partial()
        grid-column: 1 / -1
        grid-row: 2

        .VueCarousel-wrapper,
        .VueCarousel-inner
            width: 100%
            height: 100% !important

        img
            object-position: top center

    &--loadin
        +class-partial()
            img
                height: 100%
                object-fit: cover
                width: 100%
</style>
