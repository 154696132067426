<script>
import { reactive } from "@compose"

import * as vClickOutside from "v-click-outside-x"

import useAbstract from "@useAbstract"
import useLocations from "@locations/useLocations"
import useNavigation, { AVAILABLE_NAVS } from "@navigation/useNavigation"

import RouteList from "@lists/RouteList"
import SiteMasthead from "@runners/SiteMasthead"

export default {
    extends: useAbstract,
    directives: {
      clickOutside: vClickOutside.directive,
    },
    components: {
        RouteList,
        SiteMasthead
    },
    setup (props, ctx) {
        const {
            currentLocation,
            sortedLocations
        } = useLocations()

        const extendedNav = reactive(useNavigation({ type: AVAILABLE_NAVS.EXTENDED }))
        const locationNav = reactive(useNavigation({ type: AVAILABLE_NAVS.LOCATION }))

        const availableLocations = sortedLocations.value.map(
            location => ({
                ...location,
                name: location.fullName
            })
        )

        //  enable switch of whether the navigation can be closed when open
        //  this lets the user click outside of the navigation to close it
        let canClose = false
        function closeWhenActive () {
            if (!canClose) {
                canClose = locationNav.isActive
                return
            }

            ctx.emit("close", { state: !canClose })
            canClose = locationNav.isActive
        }

        return {
            extendedNav,
            locationNav,
            currentLocation,
            availableLocations,
            RouteList,
            closeWhenActive
        }
    }
}
</script>

<template lang="pug">
//- needs wrapper for clip-path to work in safari
.ExtendedNavigation-wrapper(
    v-bind="$vefa.wrapper"
    :class="{ 'is-active': locationNav.isActive }"
    v-click-outside.stop="closeWhenActive"
)
    transition(name="fx:navSlide")
        .ExtendedNavigation(
            v-bind="$vefa.element"
            v-show="locationNav.isActive"
        )
            slot(name="prepend")

            site-masthead
                template(#mastheadLogotype="{ $vefa }")
                    img.SiteMasthead-logotype(
                        src="@res/logotype-color.png"
                        v-bind="$vefa.logotype"
                    )

                template(#append)
                    slot(name="extendedNavActions")

                template(#SiteMasthead-CurrentLocationBadge-name)
                    slot(name="CurrentLocationBadge-name")

            .ExtendedNavigation-list
                transition(
                    duration="750"
                )
                    slot(
                        name="ExtendedNavigation-routes"
                        v-bind="{ $appliedVefa: $vefa.routeList }"
                        v-if="extendedNav.isActive"
                    )

                route-list(
                    title="Our Locations"
                    :apply-vefa-style="$vefa.routeList"
                    :routes="availableLocations"
                )
</template>


<vefa>
wrapper:
    o-base: put:xyEq--set:fix--z:modal flex:vAuto

element:
    class: motif:bright
    o-base: module:balance--z:modal grid:h
    o-economy: edge:xStart--set:primary

routeList:
    hed:
        class: motif:primary type:minor--weight:400--set:noWrap--set:aliased
        o-base: module:static pad:xEq50 pad:yEq25

    list:
        o-base: module:static
        o-economy: flex:vAuto

    item:
        o-base: flex:v edge:yStart--set:secondary

    link:
        # active-class: is-active
        class: ExtendedNavigation-link control:ghost--shade type:major--set:noWrap--set:aliased fx:shift
        o-base: module:static pad:xyEq50
        o-condensed: pad:xEq250 pad:yEq100
        o-enhanced: type:subhed
</vefa>

<style lang="stylus">
.ExtendedNavigation-wrapper
    pointer-events: none
    transform: translateZ(999px)
    overflow-y: scroll
    will-change: transform

    &.is-active
        pointer-events: auto

    +o-economy()
        left: auto

.ExtendedNavigation
    --left-edge: 0%
    --top-row: auto
    clip-path: polygon(var(--left-edge) 0%, var(--left-edge) 100%, 100% 100%, 100% 0%)
    grid-template-areas: unquote('
        "masthead"
        "list"
    ')
    grid-template-rows: var(--top-row) 1fr
    grid-template-columns: 1fr

    +o-economy()
        --edge-width: 2px
        --top-row: ratio(8.5)

    +o-enhanced()
        --top-row: ratio(9)

    +o-extended()
        --top-row: ratio(9.5)


.ExtendedNavigation .SiteMasthead
    grid-area: masthead

    +o-economy()
        --iso-xStart: auto

        .SiteMasthead-actions
            padding-bottom: 22px

    +o-enhanced()
        .SiteMasthead-actions
            padding-bottom: 6px


.ExtendedNavigation .SiteMasthead-hed
    +o-economy()
        display: none


.ExtendedNavigation-link
    &::before
        left: -1px
        top: -1px
        bottom: -1px

.ExtendedNavigation-list
    --edge-width: 1px
    grid-area: list


[class~='fx:navSlide-enter'],
[class~='fx:navSlide-leave-to']
    --left-edge: 100%

[class~='fx:navSlide-enter-to'],
[class~='fx:navSlide-leave']
    --left-edge: 0%

[class~='fx:navSlide-enter-active'],
[class~='fx:navSlide-leave-active']
    transition-delay: 0.25s
    transition-duration: 0.5s
    transition-timing-function: ease-in-out
    transition-property: clip-path
    will-change: clip-path
</style>
