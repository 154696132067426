<script>
import useAbstract from "@useAbstract"

export default {
    name: "FormFieldset",
    extends: useAbstract,
    props: {
        fields: {
            type: Array,
            default: () => ([])
        }
    },
}
</script>


<template lang="pug">
fieldset
    ol.FormBlock-fieldlist(
        v-bind="{ ...$attrs, ...$vefa.fieldList }"
    )
        slot
            template(
                v-if="fields.length"
            )
                component(
                    v-bind="field"
                    v-for="field in fields"
                    v-on="$listeners"
                    :is="field.component"
                    :key="field.id"
                )
</template>

<vefa>
element: {}
</vefa>
