import Vue from "vue"
import VueCompositionApi, { provide } from "@compose"

import appStyles from "@themes/v1/app.yaml"

Vue.use(VueCompositionApi)

import attachIcons from "@icons/library"
attachIcons(Vue)

Vue.config.productionTip = false

import CorporateView from "@views/CorporateView"
import VuelessView from "@views/VuelessView"

import BlogPictureStream from "@cross-sell/BlogPictureStream"
import CorporatePrimaryActions from "@corporate/CorporatePrimaryActions"
import HipcooksCookbook from "@cross-sell/HipcooksCookbook"
import HeroBlock from "@corporate/CorporateHeroBlock"
import NewsletterSignup from "@cross-sell/NewsletterSignup"

Vue.component("BlogPictureStream", BlogPictureStream)
Vue.component("CorporatePrimaryActions", CorporatePrimaryActions)
Vue.component("HeroBlock", HeroBlock)
Vue.component("HipcooksCookbook", HipcooksCookbook)
Vue.component("NewsletterSignup", NewsletterSignup)

new Vue({
    name: "Hipcooks",
    el: "#app",
    components: {
        CorporateView,
        VuelessView
    },
    setup () {
        provide("appStyles", appStyles)
    }
})
