<script>
import ContentBlock from "@blocks/ContentBlock"

export default {
    extends: ContentBlock,
}
</script>

<template lang="pug">
component(
    v-bind="$vefa.element"
    :is="tag"
)
    slot(
        name="prepend-ContentStrata"
        v-bind="$appliedVefa"
    )

    header.ContentStrata-header(
        v-bind="$vefa.header"
        v-if="hasHeader"
    )
        slot(
            name="ContentStrata-hed"
            v-bind="$appliedVefa"
        )
            h2.ContentStrata-hed(
                v-bind="$vefa.hed"
            ) {{ hed }}

        slot(
            name="ContentStrata-lede"
            v-bind="$appliedVefa"
        )
            p.ContentStrata-lede(
                v-bind="$vefa.lede"
                v-if="lede"
            ) {{ lede }}

        slot(
            name="extend-ContentStrata-header"
            v-bind="$appliedVefa"
        )

    .ContentStrata-body(
        v-bind="$vefa.body"
    )
        slot(
            name="ContentStrata-body"
            v-bind="$appliedVefa"
        )

    slot(
        name="append-ContentStrata"
        v-bind="$appliedVefa"
    )
</template>

<style lang="stylus">
.ContentStrata--
    +class-partial()
        grid-template-areas: unquote('
            ". . ."
            ". header ."
            ". body ."
            ". . ."
        ')

.ContentStrata-body
    grid-area: body

.ContentStrata-header
    grid-area: header
</style>
