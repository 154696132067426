<script>
import useAbstract from "@useAbstract"
import useFields from "@fields/useFields"

import FormField from "@fields/FormField"

export default {
    name: "TextField",
    extends: useAbstract,
    components: {
        FormField,
    },
    props: FormField.props,
    setup (props, context) {
        return useFields(props, context)
    }
}
</script>


<template lang="pug">
form-field(v-bind="$props")
    template(#default)
        input(
            v-bind="generatedProps"
            v-model="localValue"
        )
</template>

<vefa></vefa>

<style lang="stylus">
</style>
