<script>
import useAbstract from "@useAbstract"

export default {
    name: "FormErrorBlock",
    extends: useAbstract,
    props: {
        current: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: null
        }
    }
}
</script>

<template lang="pug">
aside(
    v-bind="$vefa.element"
    v-if="current"
)
    p(
        v-bind="$vefa.body"
    ) {{ message }}
</template>

<vefa></vefa>
