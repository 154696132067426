<script>
import { reactive } from "@compose"

import useAbstract from "@useAbstract"
import useNavigation, { AVAILABLE_NAVS } from "@navigation/useNavigation"

import ActionControl from "@controls/ActionControl"
import ExtendedNavigation from "@navigation/ExtendedNavigation"
import FlashList from "@notifications/FlashList"
import PublicActionsNavigation from "@navigation/PublicActionsNavigation"
import SiteColophon from "@runners/SiteColophon"
import SiteMasthead from "@runners/SiteMasthead"
import SiteNavigation from "@navigation/SiteNavigation"

export default {
    extends: useAbstract,
    components: {
        ActionControl,
        ExtendedNavigation,
        FlashList,
        PublicActionsNavigation,
        SiteColophon,
        SiteMasthead,
        SiteNavigation
    },
    props: {
        isOperational: {
            type: Boolean,
            default: true
        },
        showActionControls: {
            type: Boolean,
            default: false
        },
        showExtendedControl: {
            type: Boolean,
            default: false
        },
        showLocationControl: {
            type: Boolean,
            default: false
        },
        showLoginControl: {
            type: Boolean,
            default: false
        }
    },
    setup () {
        const extendedNav = reactive( useNavigation({ type: AVAILABLE_NAVS.EXTENDED }) )
        const locationNav = reactive( useNavigation({ type: AVAILABLE_NAVS.LOCATION }) )

        function openNavigation ({ state = true }) {
            extendedNav.setNavigationState({ state })
            locationNav.setNavigationState({ state })
        }

        function closeNavigation ({ state = false }) {
            extendedNav.setNavigationState({ state })
            locationNav.setNavigationState({ state })
        }


        return {
            locationNav,
            extendedNav,
            openNavigation,
            closeNavigation,
        }
    }
}
</script>

<template lang="pug">
#app(
    v-bind="$vefa.element"
)
    site-masthead(
        :append-vefa-style="$vefa.masthead"
        :class="{ [$style.inactive]: !isOperational }"
    )
        template(#append)
            slot(name="mastheadActions")
                public-actions-navigation.SiteMasthead-actions(
                    :show-extended-control="showExtendedControl"
                    :show-location-control="showLocationControl"
                    :show-login-control="showLoginControl"
                    @extended-nav-state-change="openNavigation"
                    @location-nav-state-change="locationNav.setNavigationState"
                )

        template(#SiteMasthead-CurrentLocationBadge-name)
            slot(name="CurrentLocationBadge-name")

    main(
        v-bind="$vefa.content"
    )
        flash-list
        slot(name="main")
        slot(name="default")

    slot(name="navigation")
        site-navigation(
            :append-vefa-style="$vefa.navigation"
            :class="{ [$style.inactive]: !isOperational }"
            @location-nav-state-change="locationNav.setNavigationState"
        )
            template(#CurrentLocationBadge-name)
                slot(name="CurrentLocationBadge-name")

            template(#siteNavigationActions)
                public-actions-navigation.SiteMasthead-actions(
                    :show-action-controls="showActionControls"
                    :show-extended-control="showExtendedControl"
                    :show-location-control="!locationNav.isActive"
                    :show-login-control="showLoginControl"
                    @extended-nav-state-change="openNavigation"
                    @location-nav-state-change="locationNav.setNavigationState"
                )

            template(#SiteNavigation-routes="props")
                slot(
                    name="SiteNavigation-routes"
                    v-bind="props"
                )


        extended-navigation(
            @close="closeNavigation"
        )
            template(#prepend)
                button.control--close(
                    class="optics:alpha50 color:primary--h:shift "
                    o-base="module:static aspect:xyEq125--text put:xEnd-yStart--set:abs--z:modal"
                    o-condensed="optics:a11y"
                    type="button"
                    @click="closeNavigation"
                )
                    cancel-icon(
                        v-bind="$vefa.icon"
                    )

                    span(
                        class="optics:a11y"
                    ) Close

            template(#CurrentLocationBadge-name)
                slot(name="CurrentLocationBadge-name")

            template(#extendedNavActions)
                public-actions-navigation.SiteMasthead-actions(
                    :show-action-controls="showActionControls"
                    :show-login-control="showLoginControl"
                    @extended-nav-state-change="closeNavigation"
                )

            template(#ExtendedNavigation-routes="props")
                slot(
                    name="ExtendedNavigation-routes"
                    v-bind="props"
                )



    site-colophon
        template(#SiteColophon-location="props")
            slot(
                name="BaseView-SiteColophon-location"
                v-bind="props"
            )

        template(#SiteColophon-menuControl)
            action-control(
                class="control:secondary"
                :apply-vefa-style="appendLocalVefa($appStyles.controls.large, $appStyles.controls.addFx)"
                @click="locationNav.setNavigationState"
            )
                template(#ActionControl-label)
                    slot(name="BaseView-SiteColophon-menuLabel") Choose Location

        template(#SiteColophon-navigation="props")
            slot(
                name="BaseView-SiteColophon-navigation"
                v-bind="props"
            )
</template>

<vefa>
element:
    class: motif:bright type:base--face:copy
    o-base: module:fill flex:v--p:start

content:
    o-base: module:balance--index:next

navigation:
    element:
        o-base: optics:no
        o-economy: optics:yes

masthead:
    element:
        class: motif:primary
        o-economy: optics:a11y
</vefa>


<style lang="stylus">
.control--close
  --put-xEnd: ratio(.5)
  --put-yStart: ratio(.5)
</style>

<style lang="stylus" module>
.inactive
    background: hsl(190, 25%, 65%)
</style>
