<script>
import { computed } from "@compose"
import useAbstract from "@useAbstract"

const tagSchema = {
    dl: {
        header: "dt",
        hed: "span",
        item: "dd"
    },
    nav: {
        header: "header",
        hed: "h5",
        item: "li"
    },
    ul: {
        item: "li"
    }
}

const allowedTags = Object.keys( tagSchema )

export default {
    extends: useAbstract,
    inheritAttrs: false,
    props: {
        routes: {
            type: Array,
            default: null
        },
        tag: {
            type: String,
            default: "dl",
            validator ( tag ) { return allowedTags.includes( tag ) }
        },
        title: {
            type: String,
            default: null
        },
    },
    setup ( props ) {
        const showHeader = computed(
            () => props.tag !== "ul" && !!props.title
        )

        return {
            elements: tagSchema[props.tag],
            showHeader
        }
    }
}
</script>

<template lang="pug">
component(
    v-bind="$vefa.element"
    :is="tag"
)
    component(
        v-bind="$vefa.header"
        v-if="showHeader"
        :is="elements.header"
    )
        component(
            v-bind="$vefa.hed"
            :is="elements.hed"
        ) {{ title }}

    template(
        v-for="route in routes"
    )
        component(
            v-bind="$vefa.item"
            :is="elements.item"
            :key="route.href"
        )
            a(
                v-bind="$vefa.link"
                :class="{ [$style.inactive]: route.active !== undefined && !route.active }"
                :href="route.slug"
            ) {{ route.shortName || route.name }}
</template>


<style lang="stylus" module>
.inactive
    color: hsl(180, 3%, 75%)
</style>
