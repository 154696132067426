<script>
import { computed, inject } from "@compose"
import useAbstract from "@useAbstract"
import useLocations from "@locations/useLocations"

import RouteList from "@lists/RouteList"
import SocialMediaList from "@socialMedia/SocialMediaList"


export default {
    extends: useAbstract,
    components: {
        SocialMediaList
    },
    setup () {
        const templateType = inject("templateType", "corporate")

        const currentId = window.location.pathname.split("/")[1]

        const {
            currentLocation,
        } = useLocations({ currentId })

        const logoLink = computed(
            () => {
                return currentLocation.value.location
                    ? `/${ currentLocation.value.location }/classes`
                    : "/"
            }
        )

        return {
            logoLink,
            RouteList,
            templateType,
        }
    }
}
</script>

<template lang="pug">
footer.SiteColophon(
    v-bind="$vefa.element"
)
    a.SiteColophon-logo(
        :href="logoLink"
    )
        figure(
            v-bind="$vefa.logo"
        )
            img(
                alt="Hipcooks logo"
                class="module:fill"
                sizes="(max-width: 500px) 100vw, 500px"
                src="@res/logo-full.condensed.png"
                srcset="@res/logo-full.condensed.png 480w, @res/logo-full.png 500w"
                width="500"
            )

            figcaption.SiteColophon-logo-tagline(
                v-bind="$vefa.tagline"
            )
                h5(
                    class="optics:a11y"
                ) Hipcooks

                p Hands-on cooking classes

    .SiteColophon-location(
        v-bind="$vefa.menuControls[templateType]"
    )
        slot(
            name="SiteColophon-location"
            v-bind="{ $appliedVefa: $vefa.contactInfo }"
        )

        slot(
            name="SiteColophon-menuControl"
        )

    social-media-list.SiteColophon-social

    .SiteColophon-navigation(
        v-bind="$vefa.navigation"
    )
        slot(
            name="SiteColophon-navigation"
            v-bind="{ RouteList }"
        )

    slot(name="copyright")
        small.SiteColophon-copyright(
            v-bind="$vefa.copyright"
        ) &copy; Hipcooks 2024. All rights reserved

</template>

<style lang="stylus">
</style>

<vefa>
element:
    class: colophon-grid--location
    o-base: module:static--index:end--z:runners flex:v--p:start--s:start grid:content

logo:
    o-base: module:static aspect:xEq80--pct
    o-condensed: aspect:xAuto

tagline:
    class: type:chrome color:primary
    o-base: put:xyEnd--set:abs
    o-economy: type:major

menuControls:
    location:
        o-base: flex:v--p:start--s:middle rhy:yStart100
        o-extended: module:static--self:yEq flex:h--p:middle--s:middle rhy:xStart150

    corporate:
        o-base: flex:v--p:start--s:middle rhy:yStart100
        o-condensed: module--self:yEq
        o-enhanced: module--self:yEnd

navigation:
    o-base: flex:v

copyright:
    class: type:chrome--weight:700 color:shade
    o-base: module:static iso:xEqAuto
    o-economy: type:base

contactInfo:
    hed:
        class: type--weight:700
</vefa>


<style lang="stylus">
.SiteColophon-logo
    grid-area: logo

.SiteColophon-logo-tagline
    +o-enhanced()
        bottom: ratio(.75)

.SiteColophon-copyright
    grid-area: copyright

.SiteColophon-location
    grid-area: location

    +over(2000px)
        align-self: center

.SiteColophon-social
    grid-area: social

.SiteColophon-navigation
    grid-area: navigation

.colophon-grid--location
    --iso-xEq: ratio(2)
    --iso-yEq: ratio(1)
    --columns: 1fr
    --rows: auto repeat(4, auto ratio(2)) auto
    grid-template-areas: unquote('
        ". . ."
        "logo logo ."
        "logo logo ."
        ". . ."
        ". location ."
        ". . ."
        ". social ."
        ". . ."
        ". navigation ."
        ". . ."
        ". copyright ."
        ". . ."
    ')

    +o-condensed()
        --columns: calc(50vw - 32px) 1fr
        --rows: ratio(.5) auto repeat(3, ratio(3) auto)
        grid-template-areas: unquote('
            ". . . ."
            "logo logo . ."
            "logo logo location ."
            ". . . ."
            ". social social ."
            ". . . ."
            ". navigation navigation ."
            ". . . ."
            "copyright copyright copyright copyright"
            ". . . ."
        ')

    +o-enhanced()
        --columns: minmax(100px, 423px) 1fr minmax(max-content, 1fr)
        --rows: auto auto auto auto ratio(3) auto ratio(3) auto
        --iso-xStart: minmax(ratio(2), ratio(4.5))
        --iso-xEnd: ratio(2)
        --iso-yEnd: ratio(2)

        grid-template-areas: unquote('
            ". . . . ."
            "logo logo . location ."
            "logo logo . location ."
            "logo logo . location ."
            "logo logo . social ."
            ". . . . ."
            "navigation navigation navigation navigation navigation"
            ". . . . ."
            "copyright copyright copyright copyright copyright"
            ". . . . ."
        ')

    +o-extended()
        --iso-xEnd: ratio(4.5)
        --rows: auto auto ratio(1) auto ratio(4) auto ratio(4) auto

    +over(2000px)
        --columns: minmax(100px, 423px) 1fr minmax(max-content, .5fr)
        --rows: auto auto ratio(1) ratio(4) auto auto
        --gap-xyEq: ratio(2)
        grid-template-areas: unquote('
            ". . . . ."
            "logo logo . . ."
            "logo logo navigation location ."
            "logo logo navigation location ."
            "logo logo navigation social ."
            ". . . . ."
            "copyright copyright copyright copyright copyright"
            ". . . . ."
        ')
</style>
