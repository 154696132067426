<script>
import useVefa, { appendLocalVefa, mergeLocalVefa, removeLocalVefa } from "@useVefa"

export default {
    inheritAttrs: false,
    setup () {
        return {
            ...useVefa()
        }
    },
    computed: {
        $appliedVefa () {
            return {
                $appliedVefa: this.$vefa || {}
            }
        }
    },
    methods: {
        appendLocalVefa,
        mergeLocalVefa,
        removeLocalVefa,
        appendStyleModule (vefa, styleModule) {
            return Object.entries(styleModule).reduce(
                (vefaContruct, [key, cls]) => {

                    if (vefaContruct[key]) {
                        const currentClassStructure = vefaContruct[key].class
                            ? vefaContruct[key].class.split(" ")
                            : []

                        if (!currentClassStructure.find( item => item === cls)) {
                            vefaContruct[key].class = `${ cls } ${ vefaContruct[key].class || "" }`
                        }
                    }

                    return vefaContruct
                },
                vefa
            )
        }
    }
}
</script>

<vefa></vefa>
