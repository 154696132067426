<script>
import useAbstract from "@useAbstract"

import BackdropMedia from "@media/BackdropMedia"
import ContentCard from "@cards/ContentCard"

export default {
    name: "InfoCard",
    extends: useAbstract,
    components: {
        BackdropMedia,
        ContentCard
    },
    props: {
        ...ContentCard.props
    }
}
</script>

<template lang="pug">
content-card.ContentCard--info(
    v-bind="$props"
    :append-vefa-style="$vefa"
)
    template(#append-ContentCard)
        backdrop-media(
            v-if="media"
            :class="$style.media"
            :media="media"
        )
</template>

<vefa>
element:
    o-base: grid:content

content:
    class: motif:bright
    o-base: module:static--z:overlay
    o-economy: grid:content--gap:xyEq0 pad:xEq100 pad:yEq25 edge:xyEq--set:shade
    style:
        --alpha-bg: .80
hed:
    class: type:major--weight:700--align:middle color:copy
    o-condensed: type:subhed
    o-extended: type:hed

body:
    class: type:minor--weight:400--align:just:xEq color:copy
    o-condensed: type:base
    o-economy: type:major--align:middle
</vefa>


<style lang="stylus" module>
.media
    overflow: hidden
</style>

<style lang="stylus">
.ContentCard--info
    --iso-xyEq: ratio(.25)
    grid-template-areas: unquote('
        ". . ."
        ". content ."
        ". . ."
    ')

    +o-condensed()
        --iso-xEnd: 1fr
        --columns: 1fr

    +o-economy()
        --rows: auto
        --iso-xEq: ratio(1)
        --iso-xEnd: ratio(1)
        --iso-yStart: ratio(2.5)
        --iso-yEnd: ratio(10)
        grid-template-areas: unquote('
            ". content ."
            ". content ."
            ". . ."
        ')

    +o-enhanced()
        --iso-yEnd: ratio(16)

    +o-extended()
        --iso-yEnd: ratio(20)

    .media--backdrop
        img
            object-position: top center

        +o-condensed()
            img
                object-position: center

        +o-economy()
            grid-row: 2/-1
</style>
