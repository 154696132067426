<script>
import { ref } from "@compose"

export default {
    name: "CurrentLocationBadge",
    setup (props, { slots }) {
        let hasLocation = ref(!!slots["CurrentLocationBadge-name"])

        return {
            hasLocation
        }
    }
}
</script>

<template lang="pug">
h3(
    v-bind="$vefa.element"
    v-if="hasLocation"
)
    slot(name="CurrentLocationBadge-name")
</template>


<vefa>
element:
    class: color:tertiary type:major--face:hed
    o-base: module:static
</vefa>
