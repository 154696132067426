<script>
import { computed } from "@compose"
import useAbstract from "@useAbstract"

export default {
    inheritAttrs: false,
    extends: useAbstract,
    props: {
        actionLabel: {
            type: String,
            default: "Action"
        },
        route: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup (props, context) {
        const is = props.route ? "a" : "button"

        const boundAttrs = computed(
            () => ({
                href: props.route || false,
                is,
                type: is == "button"
                    ? (context.attrs.type || is)
                    : false
            })
        )

        return {
            boundAttrs
        }
    }
}
</script>

<template lang="pug">
component(
    v-bind="{ ...$vefa.element, ...boundAttrs }"
    v-on="$listeners"
    :disabled="disabled"
)
    slot(name="ActionControl-content")
        slot(name="ActionControl-icon")

        span(
            v-bind="$vefa.label"
        )
            slot(name="ActionControl-label") {{ actionLabel }}
</template>
