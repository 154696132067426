<script>
import { computed } from "@compose"
import useAbstract from "@useAbstract"

function useAddressFormatter ( address ) {
    const locality = computed(
        () => {
            let locality = false
            locality = address.city || locality
            locality = address.state ? `${ locality }, ${ address.state }` : locality
            locality = address.zipcode ? `${ locality } ${ address.zipcode }` : locality

            return locality
        }
    )

    const parsedPhone = computed(
        () => {
            if ( !address.phone ) return null
            const phone = address.phone.toString()

            return [
                phone.substring( 0, 3 ),
                phone.substring( 3, 6 ),
                phone.substring( 6, 10 )
            ]
        }
    )

    return {
        locality,
        parsedPhone
    }
}

export default {
    extends: useAbstract,
    inheritAttrs: false,
    props: {
        city: {
            type: String,
            default: null
        },
        email: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        phone: {
            type: [ String, Number ],
            default: null
        },
        route: {
            type: String,
            default: null
        },
        state: {
            type: String,
            default: null
        },
        street: {
            type: String,
            default: null
        },
        zipcode: {
            type: [ String, Number ],
            default: null
        },
    },
    setup ( props ) {
        const headingTag = computed(
            () => ( props.route ? "a" : "h5" )
        )

        return {
            headingTag,
            ...useAddressFormatter( props )
        }
    }
}
</script>

<template lang="pug">
address(
    v-bind="$vefa.element"
)
    header.contact-header(
        v-bind="$vefa.header"
    )
        component.contact-hed(
            v-bind="$vefa.hed"
            :href="route"
            :is="headingTag"
        ) {{ name }}

    dl.contact-info(
        v-bind="$vefa.content"
    )
        .contact-group--address(
            v-bind="$vefa.group"
            v-if="street || locality"
        )
            dt.contact-group-title(
               v-bind="$vefa.groupLabel"
            ) Address

            dd.contact-address-street(
                v-bind="$vefa.item"
                v-if="street"
            ) {{ street }}

            dd.contact-address-locality(
                v-bind="$vefa.item"
                v-if="locality"
            ) {{ locality }}

        .contact-group--extra(
            v-bind="$vefa.group"
        )
            dt.contact-group-title(
               v-bind="$vefa.groupLabel"
            ) Contact us

            dd.contact-extra-tel(
                v-bind="$vefa.item"
                v-if="parsedPhone"
            )
                a(
                    :href="`tel:${ parsedPhone.join('') }`"
                )
                    span(
                        v-bind="$vefa.itemLabel"
                    ) Tel:&nbsp;

                    span ({{ parsedPhone[0] }}) {{ parsedPhone[1] }}-{{ parsedPhone[2] }}

            dd.contact-extra-email(
                v-bind="[$vefa.item, $vefa.itemNext]"
                v-if="email"
            )
                a(
                    :href="`mailto:${ email }`"
                )
                    span(
                        v-bind="$vefa.itemLabel"
                    ) Email:&nbsp;

                    span {{ email }}
</template>

<vefa>
element:
    o-base: module:static

groupLabel:
    class: optics:a11y

itemLabel:
    class: optics:a11y

item: {}
</vefa>
