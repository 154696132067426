<script>
import useAbstract from "@useAbstract"
import CurrentLocationBadge from "@locations/CurrentLocationBadge"

export default {
    extends: useAbstract,
    components: {
        CurrentLocationBadge
    }
}
</script>

<template lang="pug">
header.SiteMasthead(
    v-bind="$vefa.element"
)
    slot(name="prepend")

    a.SiteMasthead-hed(
        href="/"
        v-bind="$vefa.mediaFrame"
    )
        h2(class="optics:a11y") Hipcooks

        img.SiteMasthead-logo(
            src="@res/logo-cropped.png"
            v-bind="$vefa.logo"
        )

        slot(
            name="mastheadLogotype"
            v-bind="{ $vefa }"
        )
            img.SiteMasthead-logotype(
                src="@res/logotype.png"
                v-bind="$vefa.logotype"
            )

        current-location-badge.SiteMasthead-location(
            :append-vefa-style="$vefa.location"
        )
            template(#CurrentLocationBadge-name)
                slot(name="SiteMasthead-CurrentLocationBadge-name")
    slot(name="append")
</template>

<style lang="stylus">
.SiteMasthead
    ---iso-xEq: 0
    --iso-yStart: ratio(0.5)
    --iso-yEnd: ratio(0.25)
    --columns: 1fr
    --rows: auto auto
    grid-template-areas: unquote('
        ". . ."
        ". heading ."
        ". actions ."
        ". . ."
    ')

    +o-condensed()
        --iso-xStart: minmax(auto, ratio(20))
        --iso-xEnd: 0
        --columns: ratio(0.5) 1fr
        --rows: 1fr
        grid-template-areas: unquote('
            ". . . ."
            "heading . actions ."
            ". . . ."
        ')


    +o-enhanced()
        --iso-xStart: minmax(auto, ratio(24))
        --hedWidth: 360px


.SiteMasthead-actions
    grid-area: actions

    hr:last-of-type
        display: none

        +o-condensed()
            display: block

.SiteMasthead-hed
    grid-area: heading
    --logoWidth: 100px
    grid-template-areas: unquote('
        "logo logotype ."
    ')
    --columns: var(--logoWidth) 0.9fr ratio(0.5)
    --rows: 1fr
    transform: translateX(ratio(-1))

    +o-enhanced()
        --logoWidth: ratio(9)
        grid-template-columns: var(--logoWidth) 1fr ratio(0)

    .SiteMasthead-logo
        grid-area: logo

        +o-enhanced()
            position: absolute

.SiteMasthead-logotype
    grid-area: logotype

.SiteMasthead-location
    bottom: ratio(0.25)
    right: ratio(0.75)

    +o-extended()
        right: 0
</style>

<vefa>
element:
    # o-base: flex:v--p:start--s:start
    o-base: module:static--index:start--z:runners grid:content--p:start

mediaFrame:
    o-base: module:static grid:h--p:start pad:yEq50
    o-condensed: pad:yEq100

logo:
    alt: ""
    class: module:fill
    o-base: module:static
    width: 200px

logotype:
    alt: ""
    class: module:fill
    o-base: module:fill--self:yEnd
    o-enhanced: module:fill--self:yEq
    o-extended: module:fill--self:yStart iso:yStart50
    width: 488

location:
    element:
        o-base: put:xyEnd--set:abs
        o-extended: type:subhed
</vefa>
