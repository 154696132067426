import { render, staticRenderFns } from "./PublicActionsNavigation.vue?vue&type=template&id=331fda1b&lang=pug&"
import script from "./PublicActionsNavigation.vue?vue&type=script&lang=js&"
export * from "./PublicActionsNavigation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PublicActionsNavigation.vue?vue&type=custom&index=0&blockType=vefa"
if (typeof block0 === 'function') block0(component)

export default component.exports