
        import Vue from 'vue'

        const { props, computed } = Vue.config.optionMergeStrategies

        const isObject = (item) => (item && typeof item === 'object' && !Array.isArray(item))
        const appendDeep = (...objects) => {
    return objects.reduce(
        (prev, obj) => {
            Object.keys(obj).forEach(key => {
                const pVal = prev[key]
                const oVal = obj[key]

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = pVal.concat(...oVal)
                }
                else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = appendDeep(pVal, oVal)
                }
                else {
                    try {
                        let retainVal = pVal ? pVal.split(" ") : []
                        let appendVal = oVal.split(" ")
                        prev[key] = retainVal.concat(...appendVal).join(" ")
                    }
                    catch (error) {
                        prev[key] = oVal
                    }
                }
            })

            return prev
        },
        {}
    )
}
        const mergeDeep = (...objects) => {
    return objects.reduce(
        (prev, obj) => {
            Object.keys(obj).forEach(key => {
                const pVal = prev[key]
                const oVal = obj[key]

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = pVal.concat(...oVal)
                }
                else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = mergeDeep(pVal, oVal)
                }
                else {
                    prev[key] = oVal
                }
            })

            return prev
        },
        {}
    )
}
        const removeDeep = (...objects) => {
    return objects.reduce(
        (prev, obj) => {
            Object.keys(obj).forEach(key => {
                const pVal = prev[key]
                const oVal = obj[key]

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = pVal.concat(...oVal)
                }
                else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = removeDeep(pVal, oVal)
                }
                else {
                    try {
                        let retainProps = pVal ? pVal.split(" ") : []

                        if (retainProps.length) {
                            let removeProps = oVal.split(" ")

                            prev[key] = retainProps
                                .filter(
                                    item => removeProps.indexOf(item) === -1
                                )
                                .join(" ")
                        }
                        else {
                            prev[key] = oVal
                        }

                    }
                    catch (error) {
                        prev[key] = oVal
                    }
                }
            })

            return prev
        },
        {}
    )
}

        const data = {"element":{"o-base":"module:balance flex:h--p:full--s:full optics--hide:xyEq"},"content":{"o-base":"flex:v grid:content--gap:xyEq50"},"header":{"o-base":"flex:v--p:start--s:middle"},"hed":{"class":"type:major--weight:700--align:middle color:copy"},"body":{"class":"type:minor--weight:400--align:middle color:copy","o-base":"module:balance"},"footer":{"o-base":"flex:v--p:middle--s:grid pad:yStart50","o-economy":"flex:h--p:middle--s:middle pad:yStart0"}}

        export default ({ options }) => {
            if (options.__vefa) {
                options.__vefa.data = data
                return
            }

            options.__vefa = Vue.observable({ data })

            const __vefa = () => {
                const __vefa = options.__vefa && options.__vefa.data
                return __vefa
            }

            options.props = props(
                {
                    appendVefaStyle: {
                        type: [String, Object],
                        default: null
                    },
                    applyVefaStyle: {
                        type: [String, Object],
                        default: null
                    },
                    mergeVefaStyle: {
                        type: [String, Object],
                        default: null
                    },
                    removeVefaStyle: {
                        type: [String, Object],
                        default: null
                    },
                    // vefaStyle: {
                    //     type: [String, Object],
                    //     default: __vefa
                    // },
                },
                options.props
            )

            options.computed = computed(
                {
                    $vefa: ({ appendVefaStyle, applyVefaStyle, mergeVefaStyle, removeVefaStyle }) => {
                        const __vefa = options.__vefa && options.__vefa.data
                        let vefaConstruct = __vefa

                        if (isObject(applyVefaStyle)) {
                            vefaConstruct = applyVefaStyle
                        }
                        else if (__vefa && __vefa[applyVefaStyle]) {
                            vefaConstruct = __vefa[applyVefaStyle]
                        }

                        if (isObject(appendVefaStyle)) {
                            vefaConstruct = appendDeep(vefaConstruct, appendVefaStyle)
                        }
                        else if (__vefa && __vefa[appendVefaStyle]) {
                            vefaConstruct = appendDeep(vefaConstruct, __vefa[appendVefaStyle])
                        }

                        if (mergeVefaStyle) {
                            if (isObject(mergeVefaStyle)) {
                                vefaConstruct = mergeDeep(vefaConstruct, mergeVefaStyle)
                            }
                            else if (__vefa && __vefa[mergeVefaStyle]) {
                                vefaConstruct =  mergeDeep(vefaConstruct, __vefa[mergeVefaStyle])
                            }
                        }

                        if (isObject(removeVefaStyle)) {
                            vefaConstruct = removeDeep(vefaConstruct, removeVefaStyle)
                        }
                        else if (__vefa && __vefa[removeVefaStyle]) {
                            vefaConstruct = removeDeep(vefaConstruct, __vefa[removeVefaStyle])
                        }

                        if (__vefa && !Object.keys(vefaConstruct).length) {
                            vefaConstruct = __vefa
                        }

                        return vefaConstruct
                    },

                    // $$$appliedVefa () {
                    //     return {
                    //         $appliedVefa: this.$$$vefa
                    //     }
                    // }
                },
                options.computed
            )
        }
    