export const checkHoneypot = ({ fields }) => {
    let honeypot = fields.find(
        ({ component }) => component.name == "HoneypotField"
    )

    return (honeypot && !!honeypot.value)
}


export const exposeFieldData = ({ fields, formMetaFields }) => {
    const localReducer = (data, { name, value }) => {
        data[name] = value
        return data
    }

    const fieldData = fields.reduce( localReducer, {} )
    const fieldMetaData = formMetaFields.reduce( localReducer, {} )

    return {
        ...fieldData,
        ...fieldMetaData
    }
}


export const packFieldData = ({ fields, formMetaFields }) => {
    const localMapper =  ({ name, value }) => ({ name, value })
    const fieldData = fields.map( localMapper )
    const fieldMetaData = formMetaFields.map( localMapper )

    return {
        fieldData: [
            ...fieldData,
            ...fieldMetaData
        ]
    }
}


export const packFormData = ({ fields, formMetaFields }) => {
    let formData = new FormData()
    const localMapper = ({ name, value }) => formData.append(name, value)

    fields.forEach( localMapper )
    formMetaFields.forEach( localMapper )

    formData.append("host", location.host)

    return { formData }
}


export const serializeFormData = ({ fields }) => {
    let searchParams = new URLSearchParams()

    fields.forEach(
        ({ name, value }) => searchParams.append(name, value)
    )

    return searchParams.toString()
}
