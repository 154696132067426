export const isObject = (item) => (item && typeof item === 'object' && !Array.isArray(item))

export const mergeDeep = (...objects) => {
    return objects.reduce(
        (prev, obj) => {
            Object.keys(obj).forEach(key => {
                const pVal = prev[key]
                const oVal = obj[key]

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = pVal.concat(...oVal)
                }
                else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = mergeDeep(pVal, oVal)
                }
                else {
                    prev[key] = oVal
                }
            })

            return prev
        },
        {}
    )
}

export const expandComplexKey = ({key, value}) => {
    const sourceTree = key.split('.')
    return sourceTree.reduceRight(
        (propObject, prop, index, propsArr) => ({
            [prop]: (propsArr.length == index + 1) ? value : propObject
        }),
        {}
    )
}

export const JSONcopy = original => JSON.parse( JSON.stringify(original) )
