<script>
import useAbstract from "@useAbstract"

export default {
    extends: useAbstract,
    props: {
        alt: {
            type: String,
            default: ""
        },
        media: {
            type: String,
            default: null
        }
    }
}
</script>

<template lang="pug">
.media--backdrop(
    v-bind="$vefa.element"
    v-if="media"
)
    img(
        v-bind="$vefa.media"
        :alt="alt"
        :src="require(`@res/${ media }`)"
    )
</template>

<vefa>
element:
    o-base: module:static

media:
    class: module:fill put:xyEq--set:abs fx:shift
</vefa>

<style lang="stylus">
.media--backdrop
    grid-column: 1 / -1
    grid-row: 1 / -1

    img
        object-fit: cover
</style>
