import { isObject } from "@utils/objects"
import { inject } from "@compose"


function areBothConstructsArrays (currentStyleConstruct, originalStyleContruct) {
    return (Array.isArray(currentStyleConstruct) && Array.isArray(originalStyleContruct))
}


function areBothConstructsObjects (currentStyleConstruct, originalStyleContruct) {
    return (isObject(currentStyleConstruct) && isObject(originalStyleContruct))
}


function applyStyleValue ({ key, appliedValue, constructedStyleMap }) {
    constructedStyleMap[key] = appliedValue
}


function appendStyleValue ({ key, appliedValue, constructedValue, constructedStyleMap }) {
    try {
        let retainVal = constructedValue ? constructedValue.split(" ") : []
        let appendVal = appliedValue.split(" ")
        applyStyleValue({
            key,
            constructedStyleMap,
            appliedValue: retainVal.concat(...appendVal).join(" ")
        })
    }
    catch (error) {
        applyStyleValue({ key, appliedValue, constructedStyleMap })
    }
}


function removeStyleValue ({ key, appliedValue, constructedValue, constructedStyleMap }) {
    try {
        let retainProps = constructedValue ? constructedValue.split(" ") : []

        if (retainProps.length) {
            let removeProps = appliedValue.split(" ")
            applyStyleValue({
                key,
                constructedStyleMap,
                appliedValue: retainProps
                    .filter(
                        item => removeProps.indexOf(item) === -1
                    )
                    .join(" ")
            })
        }
        else {
            applyStyleValue({ key, appliedValue, constructedStyleMap })
        }

    }
    catch (error) {
        applyStyleValue({ key, appliedValue, constructedStyleMap })
    }
}


function setVefaStyle ({ mainFn, recursiveFn }) {
    return function (constructedStyleMap, styleElement) {
        Object.keys(styleElement).forEach(key => {
            const constructedValue = constructedStyleMap[key]
            const appliedValue = styleElement[key]

            if (areBothConstructsArrays(constructedValue, appliedValue)) {
                applyStyleValue({
                    key,
                    constructedStyleMap,
                    appliedValue: constructedValue.concat(...appliedValue)
                })
            }
            else if (areBothConstructsObjects(constructedValue, appliedValue)) {
                applyStyleValue({
                    key,
                    constructedStyleMap,
                    appliedValue: recursiveFn(constructedValue, appliedValue)
                })
            }
            else {
                mainFn({ key, appliedValue, constructedValue, constructedStyleMap })
            }
        })

        return constructedStyleMap
    }
}


export function appendLocalVefa (...vefaStyleObjects) {
    return vefaStyleObjects.reduce(
        setVefaStyle({
            mainFn: appendStyleValue,
            recursiveFn: appendLocalVefa
        }),
        {}
    )
}


export function mergeLocalVefa (...vefaStyleObjects) {
    return vefaStyleObjects.reduce(
        setVefaStyle({
            mainFn: applyStyleValue,
            recursiveFn: mergeLocalVefa
        }),
        {}
    )
}


export function removeLocalVefa (...vefaStyleObjects) {
    return vefaStyleObjects.reduce(
        setVefaStyle({
            mainFn: removeStyleValue,
            recursiveFn: removeLocalVefa
        }),
        {}
    )
}


export default function () {
    // let {
    //     appendVefaStyle,
    //     applyVefaStyle,
    //     mergeVefaStyle,
    //     removeVefaStyle,
    //     // vefaStyle: $vefa,
    // } = vefaProps

    // function determineApplication (appliedVefaStyle, fn) {
    //     if (isObject(appliedVefaStyle)) {
    //         $vefa = fn($vefa, appliedVefaStyle)
    //     }
    //     else if ($vefa && $vefa[appliedVefaStyle]) {
    //         $vefa = fn($vefa, $vefa[appliedVefaStyle])
    //     }
    // }

    // determineApplication(applyVefaStyle, ($vefa, appliedVefa) => appliedVefa)
    // determineApplication(appendVefaStyle, appendLocalVefa)
    // determineApplication(mergeVefaStyle, mergeLocalVefa)
    // determineApplication(removeVefaStyle, removeLocalVefa)

    const $appStyles = inject("appStyles", {})

    return {
        $appStyles: $appStyles,
        // $vefa: $vefa || {},
        // ...(useFunctions && { appendLocalVefa: appendLocalVefa }),
        // ...(useFunctions && { mergeLocalVefa: mergeLocalVefa }),
        // ...(useFunctions && { removeLocalVefa: removeLocalVefa }),
    }
}
