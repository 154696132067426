import { computed, reactive, toRefs } from "@compose"

import navSchemas from "@content/navigation.yaml"

import { parseObject } from "@utils/parsers"
let navRoutes = parseObject({ id: "NAV_ROUTES" })

export const AVAILABLE_NAVS = {
    EXTENDED: "extended",
    LOCATION: "location"
}

const navState = {
    [AVAILABLE_NAVS.EXTENDED]: {
        name: "Extended",
        isActive: true,
        // navSchema: NavSchemas[AVAILABLE_NAVS.EXTENDED]
    },
    [AVAILABLE_NAVS.LOCATION]: {
        name: "Locations",
        isActive: false,
        // navSchema: NavSchemas[AVAILABLE_NAVS.COLOPHON]
    }
}

function mapRouteCollections ({ routes }) {
    routes.forEach(
        collection => {
            collection.routes = mapRouteList({ routes: collection.routes })
        }
    )

    return routes
}

function mapRouteList ({ routes }) {
    return routes.map(
        route => {
            return {
                ...route,
                slug: navRoutes[route.slug] || route.slug
            }
        }
    )
}


export default ({ type, includeSchema } = {}) => {
    const navigationState = reactive(navState[type] || {})

    const setNavigationState  = ({ state = !navigationState.isActive }) =>
        navigationState.isActive = state

    const routes = computed(
        () => {
            try {
                const routes = Array.isArray(includeSchema)
                    ? includeSchema.reduce(
                        (routes, include) => {
                            navSchemas[include] && routes.push(navSchemas[include])
                            return routes
                        },
                        []
                    )
                    : navSchemas[includeSchema] || []

                return (includeSchema.length == 1)
                    ? routes[0].routes
                    : routes
            }
            catch (err) {
                return []
            }
        }
    )

    const localizedRoutes = computed(
        () => {
            try {
                const dispatch = {
                    collection: mapRouteCollections,
                    list: mapRouteList
                }

                const type = Array.isArray(includeSchema) && (includeSchema.length > 1)
                    ? "collection"
                    : "list"

                return dispatch[type]({ routes: routes.value })
            }
            catch (err) {
                return routes
            }
        }
    )

    const localizeRoute = ({ route }) => navRoutes[route] || ""

    return {
        //  data props
        ...toRefs(navigationState),
        localizedRoutes,
        routes,

        //  methods
        localizeRoute,
        setNavigationState,
    }
}
