<script>
import { provide } from "@compose"
import useAbstract from "@useAbstract"
import useLocations from "@locations/useLocations"

import HipcooksBase from "@views/HipcooksBase"
import ContactInfo from "@contacts/ContactInfo"

const devComponents = [
    "HeroBlock",
    "CorporatePrimaryActions",
    "BlogPictureStream",
    "HipcooksCookbook",
    "NewsletterSignup"
]

export default {
    extends: useAbstract,
    components: {
        ContactInfo,
        HipcooksBase,
    },
    setup () {
        provide( "templateType", "corporate" )

        const {
            currentLocation,
            sortedLocations
        } = useLocations()


        return {
            isDev: ( process.env.NODE_ENV == "development" ),
            currentLocation,
            sortedLocations,

            devComponents,
        }
    }
}
</script>


<template lang="pug">
hipcooks-base.CorporateView(
    show-location-control
)
    template(#main)
        template(v-if="isDev")
            component(
                v-for="item in devComponents"
                :is="item"
                :key="item"
            )

        template(v-else)
            slot(#default)


    template(#BaseView-SiteColophon-navigation)
        .SiteColophon-locations(
            v-bind="$vefa.colophonNavigation.wrapper"
        )
            template(v-for="location in sortedLocations")
                contact-info(
                    v-if="location.contact"
                    v-bind="{ ...location, name: location.fullName }"
                    :append-vefa-style="$vefa.colophonNavigation"
                    :class="{ [$style.inactive]: !location.active }"
                    :key="location.name"
                )
</template>


<style lang="stylus">
.CorporateView
    .SiteColophon-locations
        +o-condensed()
            --cell-count: 2
            --columns: repeat(var(--cell-count), max-content)

        +o-economy()
            --cell-count: 3

        +o-extended()
            --cell-count: 4


    .SiteNavigation
        +o-economy()
            --nav-height: ratio(8)


    .ExtendedNavigation
        +o-economy()
            --top-row: ratio(8)

        +o-enhanced()
            --top-row: ratio(8)
</style>

<style lang="stylus" module>
.inactive
    opacity: .5
</style>

<vefa-css>
contact:
    element:
        class: type:minor--lead:expand
        o-enhanced: type:base

colophonNavigation:
    wrapper:
        o-base: rhy:yStart100
        o-condensed: flex:h:wrap--p:middle--s:full grid:v--gap:xEq300--gap:yEq200 rhy:yStart0
        o-economy: grid:v--gap:xEq100--gap:yEq200
        o-enhanced: grid:v--gap:xEq300--gap:yEq200

    element:
        class: module:static type:minor--lead:expand
        o-base: module:static--self:yStart flex:vAuto--p:start--s:start

    hed:
        class: type:base--weight:700--case:upper--h:shift color:primary fx:shift

    link:
        class: type--set:noWrap--h:underline fx:shift
</vefa-css>
