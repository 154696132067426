<script>
import useAbstract from "@useAbstract"

import BackdropMedia from "@media/BackdropMedia"
import ContentBlock from "@blocks/ContentBlock"


export default {
    extends: useAbstract,
    components: {
        BackdropMedia,
        ContentBlock
    },
    props: {
        ...ContentBlock.props,
        tag: {
            type: String,
            default: "section"
        },
        media: {
            type: String,
            default: null
        },
    },
}
</script>

<template lang="pug">
content-block.ContentBlock--hero(
    v-bind="{ tag, media, hed, lede }"
    :append-vefa-style="$vefa.append"
    :merge-vefa-style="$vefa.merge"
)
    template(#prepend-ContentBlock)
        slot(
            name="prepend-HeroBlock"
        )
            backdrop-media(
                v-if="media"
                :media="media"
            )

    template(#append-ContentBlock)
        slot(
            name="append-HeroBlock"
        )

</template>

<vefa>
append:
    header:
        class: motif:dark
        o-base: pad:xyEq50
        o-condensed: pad:xyEq100 rhy:yStart50
        o-economy: pad:xyEq150 rhy:yStart100
        o-extended: pad:xyEq200

merge:
    hed:
        class: type:major--weight:700--set:aliased color:primary
        o-condensed: type:hed

    lede:
        class: type:minor--weight:400--lead:base color:primary
        o-condensed: type:base
        o-extended: type--lead:double
</vefa>


<style lang="stylus">
.ContentBlock--hero
    --columns: 1fr
    --rows: auto
    // 58.125vw = 279H/480W
    --iso-yStart: s("calc(58.125vw - %s)", ratio(2))
    grid-template-areas: unquote('
        ". . ."
        "header header header"
        ". . ."
    ')

    +o-condensed()
        --iso-xEnd: .5fr
        grid-template-areas: unquote('
            ". . ."
            "header header ."
            ". . ."
        ')

    +o-economy()
        // 46.25vw = 555H/1200W
        --iso-yStart: 1fr
        --iso-xEnd: 1fr
        --columns: minmax(min-content, 70%)
        --rows: min-content
        grid-template-areas: unquote('
            ". . ."
            ". header ."
            ". . ."
        ')
        height: 555px

    +o-extended()
        --columns: minmax(min-content, 40%)
        height: 50vw
        max-height: 650px


    .block-header
        background: alpha(colors.copy, .6)
        // --alpha-bg: .6

    .media--backdrop
        img
            object-position: top center
</style>
